import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AUTH_TOKENS_KEY } from '../enums/authEnums';
import {
  sendLogInRequestAPI,
  sendLogOutRequestAPI,
  sendLogInViaMagicLinkRequestAPI,
} from '../utils/api/authAPI';
import getUrlParam from '../utils/helpers/getUrlParamHelper';
import Spinner from '../components/molecules/Spinner';

const AuthContext = React.createContext({});

const existingTokens = JSON.parse(localStorage.getItem(AUTH_TOKENS_KEY));
const redirectUrl = getUrlParam('url');

export const AuthContextProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(existingTokens);
  const [hasRedirected, setHasRedirected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  /**
   * Updates authentication tokens
   *
   * @param payload {object || null}
   */
  const updateAuthTokens = (payload) => {
    setAuthTokens(payload);

    if (payload) {
      localStorage.setItem(AUTH_TOKENS_KEY, JSON.stringify(payload));
    } else {
      localStorage.removeItem(AUTH_TOKENS_KEY);
    }
  };

  /**
   * Handles success submit event for log in
   *
   * @param authTokensData {object}
   */
  const handleLoginSubmitted = (authTokensData) => {
    // TODO: implement secure auth
    updateAuthTokens(authTokensData);

    toast.success('Authorised!');
  };

  /**
   * Handler to log in
   *
   * @param payload {object}
   * @param callback {function}
   */
  const logInHandler = (payload, callback) => {
    sendLogInRequestAPI(payload)
      .then((response) => {
        handleLoginSubmitted(response);
      })
      .catch(() => {
        toast.error(`The email or password is incorrect. Please try again!`);
      })
      .finally(() => {
        callback();
      });
  };

  /**
   * Handler to log out
   */
  const logOutHandler = () => {
    setIsLoading(true);

    sendLogOutRequestAPI()
      .then(() => {
        updateAuthTokens(null);

        toast.success('Logged out!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Log in via magic link
   */
  const logInViaMagicLink = () => {
    const token = getUrlParam('token');

    if (token) {
      setIsLoading(true);

      sendLogInViaMagicLinkRequestAPI(token)
        .then((response) => {
          handleLoginSubmitted(response);
        })
        .catch((error) => {
          toast.error(error.response.data.message || 'Token is invalid');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (authTokens && redirectUrl && !hasRedirected) {
      navigate(redirectUrl);

      setHasRedirected(true);
    }
  }, [authTokens]);

  useEffect(() => {
    if (!existingTokens) {
      logInViaMagicLink();
    }

    document.addEventListener('unauthenticated status-401', () => {
      updateAuthTokens(null);

      toast.error('Unauthorised!');

      localStorage.removeItem(AUTH_TOKENS_KEY);
    });

    document.addEventListener('unauthenticated status-429', () => {
      updateAuthTokens(null);

      toast.error('Too many request to server! Please wait!');
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authTokens,
        updateAuthTokens,
        logInHandler,
        logOutHandler,
      }}
    >
      {isLoading && <Spinner />}

      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
