import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render Breadcrumb navigation
 */
const BreadCrumbs = ({ path }) => (
  <div className="breadcrumbs">
    <h2>{['Vendor Portal', ...path].filter((str) => str.length).join(' / ')}</h2>
  </div>
);

BreadCrumbs.propTypes = {
  path: PropTypes.array.isRequired,
};

export default BreadCrumbs;
