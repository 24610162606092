import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

import { formatDate as formatDateUtil } from '../../../utils/dateUtils';
import ErrorBoundaryDecorator from '../ErrorBoundaryDecorator';
import InputLabelField from '../../molecules/InputLabelField';
import PopupTitle from '../../atoms/PopupTitle';
import Label from '../../atoms/Label';
import Button from '../../atoms/Button';
import SmallSpinner from '../../atoms/SmallSpinner';

/**
 * Add payment form
 */
const AddPaymentForm = ({ className, isModalMode, onSubmit }) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [paidAmountValue, setPaidAmountValue] = useState(0);
  const [paymentMethodValue, setPaymentMethodValue] = useState('');
  const [paymentDateValue, setPaymentDateValue] = useState('');

  const isSubmittingDisabled =
    paidAmountValue <= 0 || paymentMethodValue.length === 0 || paymentDateValue.length === 0;

  /**
   * Handles onSubmit/onClick event to submit form
   *
   * @param e {object}
   * @param enableCloseModalAfterSubmit {boolean}
   */
  const onSubmitFormHandler = (e, enableCloseModalAfterSubmit) => {
    e.preventDefault();

    setIsFormSubmitting(true);

    const data = {
      cost_price_invoiced_amount: paidAmountValue,
      cost_price_invoiced_payment_method: paymentMethodValue,
      cost_price_invoiced_payment_date: paymentDateValue,
    };

    const callbackAfterSubmit = (enableClearForm = true) => {
      if (enableClearForm) {
        setPaidAmountValue(0);
        setPaymentMethodValue('');
        setPaymentDateValue('');
      }

      setIsFormSubmitting(false);
    };

    onSubmit(data, callbackAfterSubmit, enableCloseModalAfterSubmit);
  };

  /**
   * Handles onChange event of <DayPickerInput> "day"
   *
   * @param date {string}
   */
  const onDayChangeHandler = (date) => setPaymentDateValue(formatDateUtil(date));

  /**
   * Render btns (or spinner if form is submitting)
   */
  const renderBtns = () => {
    if (isFormSubmitting) {
      return <SmallSpinner />;
    }

    if (isModalMode) {
      return (
        <div className="add-payment-form__btns-wrapper">
          <Button
            className="btn-submit"
            onClick={(e) => onSubmitFormHandler(e, true)}
            disabled={isSubmittingDisabled}
          >
            Add and close
          </Button>

          <Button type="submit" className="btn-submit" disabled={isSubmittingDisabled}>
            Add and add more
          </Button>
        </div>
      );
    }

    return (
      <Button type="submit" className="btn-submit" disabled={isSubmittingDisabled}>
        Submit
      </Button>
    );
  };

  return (
    <div className={clsx('add-payment-form', className)}>
      <PopupTitle>Add vendor payment</PopupTitle>

      <form onSubmit={onSubmitFormHandler}>
        <InputLabelField
          value={paidAmountValue}
          onChange={(e) => setPaidAmountValue(e.target.value)}
          labelContent="Paid Amount *:"
          id="add-payment__paid-amount"
          type="text"
          isLabelFirst
        />

        <InputLabelField
          value={paymentMethodValue}
          onChange={(e) => setPaymentMethodValue(e.target.value)}
          labelContent="Payment method *:"
          id="add-payment__payment-method"
          type="text"
          isLabelFirst
        />

        <div className="input-label-field">
          <Label labelContent="Payment date *:" htmlFor="add-payment__payment-date" />

          <DayPickerInput
            value={paymentDateValue}
            format="YYYY-MM-DD"
            formatDate={formatDate}
            parseDate={parseDate}
            onDayChange={onDayChangeHandler}
          />
        </div>

        {renderBtns()}
      </form>
    </div>
  );
};

AddPaymentForm.propTypes = {
  className: PropTypes.string,
  isModalMode: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
};

AddPaymentForm.defaultProps = {
  className: '',
  isModalMode: false,
};

export default ErrorBoundaryDecorator()(AddPaymentForm);
