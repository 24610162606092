import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/atoms/Button';

/**
 * Render contract content
 */
const ContractContent = ({ contract }) => {
  /**
   * Opens file located by link in new window
   */
  const onHandleDownloadClick = () => {
    window.open(contract.link, '_blank');
  };

  return (
    <div className="white-block-container">
      {contract ? (
        <>
          <h2>{contract.display_name || 'Contract'}</h2>

          <Button className="btn-submit" onClick={onHandleDownloadClick}>
            Download contract
          </Button>
        </>
      ) : (
        <h2>No contract uploaded</h2>
      )}
    </div>
  );
};

ContractContent.propTypes = {
  contract: PropTypes.object,
};

ContractContent.defaultProps = {
  contract: null,
};

export default ContractContent;
