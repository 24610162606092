import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  getPaymentsBySelectedPropertyRequestAPI,
  sendAddPaymentRequestAPI,
  sendDeletePaymentRequestAPI,
} from '../utils/api/paymentsAPI';
import VendorsSelectedDataContext from './VendorsSelectedDataContext';
import { customConfirm } from '../utils/customModalsUtils';

const PaymentsContext = React.createContext({});

export const PaymentsContextProvider = ({ children }) => {
  const [payments, setPayments] = useState([]);
  const [arePaymentsLoading, setArePaymentsLoading] = useState(false);
  const [selectedPropertyId, setSelectedPropertyId] = useState(null);
  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] = useState(false);

  const { allProperties, selectedProperty } = useContext(VendorsSelectedDataContext);

  /**
   * Get payments by selected property from API
   */
  const getPayments = () => {
    setArePaymentsLoading(true);

    getPaymentsBySelectedPropertyRequestAPI(selectedPropertyId || selectedProperty?.id)
      .then((response) => {
        setPayments(response.data.data);
      })
      .finally(() => {
        setArePaymentsLoading(false);
      });
  };

  /**
   * Handler to open add payment modal
   *
   * @param booking {object}
   */
  const openAddPaymentModalHandler = (booking) => {
    const { id } = _.find(allProperties, { code: booking.property });

    setSelectedPropertyId(id);

    setTimeout(() => {
      setIsAddPaymentModalOpen(true);
    });
  };

  /**
   * Handler to close add payment modal
   */
  const closeAddPaymentModalHandler = () => {
    setSelectedPropertyId(null);
    setIsAddPaymentModalOpen(false);
    setPayments([]);
  };

  /**
   * Handles onSubmit event for form
   *
   * @param payload {object}
   * @param callback {function}
   * @param enableCloseModalAfterSubmit {boolean}
   */
  const onSubmitPaymentFormHandler = (payload, callback, enableCloseModalAfterSubmit = false) => {
    sendAddPaymentRequestAPI(selectedPropertyId || selectedProperty?.id, payload)
      .then(() => {
        if (enableCloseModalAfterSubmit) {
          closeAddPaymentModalHandler();
        }

        toast.success('Saved!');

        callback();

        getPayments();
      })
      .catch((response) => {
        callback(false);

        const errorBag = response.data.errors;

        Object.keys(errorBag).forEach((errorField) => {
          toast.error(errorBag[errorField][0]);
        });
      });
  };

  /**
   * Handles delete event for payment
   *
   * @param payment {object}
   */
  const onDeletePaymentHandler = (payment) => {
    const { id: propertyId, name: propertyName } =
      selectedProperty || _.find(allProperties, { id: selectedPropertyId });
    const { cost_price_invoiced_amount: paidAmount } = payment;

    const confirmMsg = `Delete a payment of ${paidAmount} USD, submitted to ${propertyName}?`;

    const onConfirmDelete = () => {
      setArePaymentsLoading(true);

      sendDeletePaymentRequestAPI(propertyId, payment)
        .then(() => {
          toast.success('Deleted!');

          getPayments();
        })
        .catch((response) => {
          setArePaymentsLoading(false);

          toast.error(response.data.message);
        });
    };

    customConfirm(confirmMsg, {
      onOk: onConfirmDelete,
    });
  };

  useEffect(() => {
    if (selectedProperty || selectedPropertyId) {
      getPayments();
    } else {
      setPayments([]);
    }
  }, [selectedProperty, selectedPropertyId]);

  return (
    <PaymentsContext.Provider
      value={{
        payments,
        arePaymentsLoading,
        isAddPaymentModalOpen,
        openAddPaymentModalHandler,
        closeAddPaymentModalHandler,
        onSubmitPaymentFormHandler,
        onDeletePaymentHandler,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
};

export default PaymentsContext;
