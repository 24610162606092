import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
import DayPicker, { DateUtils } from 'react-day-picker';

import ErrorBoundaryDecorator from '../../organisms/ErrorBoundaryDecorator';
import Button from '../../atoms/Button';

/**
 * Custom calendar
 */
const CustomCalendar = ({ inventory, onRangeSelected }) => {
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [enteredTo, setEnteredTo] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [month, setMonth] = useState(new Date());

  /**
   * Checks if first date in calendar being selected
   *
   * @param from {string}
   * @param to {string}
   * @param day {string}
   *
   * @return {boolean}
   */
  const isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;

    return !from || isBeforeFirstDay || isRangeSelected;
  };

  /**
   * Handles onClick event of reset selection button
   */
  const handleResetClick = () => {
    setDateFrom(null);
    setDateTo(null);
    setEnteredTo(null);
  };

  /**
   * Handles onClick event of day cell
   *
   * @param day {string}
   */
  const onDayClickHandler = (day) => {
    if (dateFrom && dateTo && day >= dateFrom && day <= dateTo) {
      handleResetClick();
    } else {
      if (isSelectingFirstDay(dateFrom, dateTo, day)) {
        setDateFrom(day);
        setDateTo(null);
        setEnteredTo(null);
      } else {
        setDateTo(day);
        setEnteredTo(day);

        onRangeSelected(dateFrom, dateTo, day);
      }
    }
  };

  /**
   * Handles onMouseEnter event of day
   *
   * @param day {string}
   */
  const handleDayMouseEnter = (day) => {
    if (!isSelectingFirstDay(dateFrom, dateTo, day)) {
      setEnteredTo(day);
    }
  };

  /**
   * Render day cell
   *
   * @param day {object}
   *
   * @return {JSX.Element}
   */
  const renderDay = (day) => {
    const date = day.getDate();

    const getRoomQuantity = () => {
      const item = _.find(inventory, { date: moment(day).format('YYYY-MM-DD') });

      return _.isUndefined(item) ? undefined : item.qty;
    };

    const itemQty = getRoomQuantity();

    return (
      <div className="day" title={itemQty === null ? 'room is always available' : ''}>
        <div className="day__row">
          <span className="day__date">{date}</span>
        </div>

        <div className="day__row">
          <span
            className={clsx('day__availability', {
              none: itemQty === 0,
              'not-enough': itemQty <= 5 && itemQty >= 1,
              enough: itemQty > 5 || itemQty === null,
            })}
          >
            {itemQty === null ? '∞' : itemQty}
          </span>
        </div>
      </div>
    );
  };

  /**
   * Render calendar caption
   *
   * @return {JSX.Element}
   */
  const renderCalendarCaption = () => (
    <div className="calendar__caption">
      {!dateFrom && !dateTo && 'Please select the first day'}
      {dateFrom && !dateTo && 'Please select the last day'}
      {dateFrom &&
        dateTo &&
        `Selected from ${dateFrom.toLocaleDateString()} to ${dateTo.toLocaleDateString()}`}
    </div>
  );

  return (
    <div className="calendar-wrapper">
      {renderCalendarCaption()}

      <div className="btn-clear__wrapper">
        {dateFrom && (
          <Button className="btn-clear" onClick={handleResetClick}>
            clear selection
          </Button>
        )}
      </div>

      <DayPicker
        modifiers={{ start: dateFrom, end: enteredTo }}
        selectedDays={[dateFrom, { from: dateFrom, to: enteredTo }]}
        disabledDays={{ before: dateFrom, after: dateTo }}
        numberOfMonths={2}
        renderDay={renderDay}
        month={month}
        onDayClick={(day) => onDayClickHandler(day)}
        onDayMouseEnter={handleDayMouseEnter}
      />

      <div className="calendar__legend">
        <span>∞</span> &mdash; room is always available
      </div>
    </div>
  );
};

CustomCalendar.propTypes = {
  inventory: PropTypes.array,
  onRangeSelected: PropTypes.func.isRequired,
};

CustomCalendar.defaultProps = {
  inventory: [],
};

export default ErrorBoundaryDecorator()(CustomCalendar);
