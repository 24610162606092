import apiClient from '../../services/apiClient';
import processVendorsRequest from '../helpers/processVendorsRequestHelper';

/**
 * Get "vendors" request
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getVendorsRequestAPI = () =>
  apiClient
    .get('vendors')
    .then((response) => {
      const vendors = processVendorsRequest(response.data.data);

      return Promise.resolve(vendors);
    })
    .catch((error) => Promise.reject(error));
