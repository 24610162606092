import React, { Component } from 'react';

import { LOGGER_ERRORS } from '../../../enums/errorMessagesEnums';
import forwardHoc from '../../../HOC/forwardHoc';
import ErrorBoundariesComponent from './UI/ErrorBoundariesComponent';

/**
 * Component to catch an error
 * withMessage === true only for pages or templates when error occurred for whole page
 *
 * @param withMessage {boolean}
 *
 * @return {JSX.Element}
 */
const ErrorBoundaryDecorator = (withMessage = false) => (WrappedComponent) => {
  class DecoratedComponent extends Component {
    // eslint-disable-next-line react/no-unused-class-component-methods,react/static-property-placement
    displayName = 'ErrorBoundaryDecorator';

    state = {
      hasError: null,
    };

    componentDidCatch(error, info) {
      this.setState({ hasError: error });

      window.Logger.error(LOGGER_ERRORS.BOUNDARIES, { error, info });
    }

    render() {
      // eslint-disable-next-line react/prop-types
      const { forwardedRef, ...props } = this.props;

      if (this.state.hasError) {
        return withMessage && <ErrorBoundariesComponent />;
      }

      return <WrappedComponent {...props} ref={forwardedRef} />;
    }
  }

  // Coz refs are not passed down with {...props},
  // we need to write some additional code to not brake refs with our decorator
  // see https://reactjs.org/docs/forwarding-refs.html
  return forwardHoc(WrappedComponent, DecoratedComponent, 'ErrorBoundaryDecorator');
};

export default ErrorBoundaryDecorator;
