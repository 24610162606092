import PropTypes from 'prop-types';

export const ModalPropTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  className: PropTypes.string,
  withCloseButton: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  shouldCloseOnOverlayClick: PropTypes.bool,
};

export const ModalDefaultProps = {
  className: '',
  withCloseButton: true,
  shouldCloseOnEsc: true,
  shouldCloseOnOverlayClick: true,
};
