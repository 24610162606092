import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import AuthContext from '../../../../context/AuthContext';
import Navigation from './Navigation';

/**
 * Public wrapper for secured routes
 */
const PublicRoute = ({ restricted, hideNavigation, children }) => {
  const { authTokens } = useContext(AuthContext);

  // restricted = false meaning public route
  // restricted = true meaning restricted route
  if (authTokens && restricted) {
    return <Navigate to="/" />;
  }

  if (!hideNavigation) {
    return (
      <div className="navigation-wrapper">
        <Navigation />

        {children}
      </div>
    );
  }

  return children;
};

PublicRoute.propTypes = {
  restricted: PropTypes.bool,
  hideNavigation: PropTypes.bool,
};

PublicRoute.defaultProps = {
  restricted: false,
  hideNavigation: false,
};

export default PublicRoute;
