import React from 'react';
import PropTypes from 'prop-types';

import ReportModel from '../../../../models/ReportModel';
import ErrorBoundaryDecorator from '../../../organisms/ErrorBoundaryDecorator';
import PopupTitle from '../../../atoms/PopupTitle';
import Button from '../../../atoms/Button';

/**
 * Render import report
 */
const ImportReport = ({ data, onClick }) => {
  const { title, errors } = data;

  const renderListOfErrors = () => (
    <ul className="errors-list">
      {errors.map(({ error, qty }) => (
        <li className="errors-list__item">
          <span className="error-name">{`${error}. `}</span>
          <span className="error-desc">{`(rows affected: ${qty})`}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="report-wrapper">
      <PopupTitle>Upload / import inventory report</PopupTitle>

      <div className="error-title">{title}</div>

      {errors.length && renderListOfErrors()}

      <Button onClick={onClick} className="btn-submit">
        {errors.length ? 'Go to inventory and try again' : 'View the changes'}
      </Button>
    </div>
  );
};

ImportReport.propTypes = {
  data: PropTypes.instanceOf(ReportModel),
  onClick: PropTypes.func,
};

ImportReport.defaultProps = {
  data: new ReportModel(),
  onClick: () => {},
};

export default ErrorBoundaryDecorator()(ImportReport);
