const COMMON_PARTS = {
  WE_ARE_WORKING_ON: 'We are working on that - please revisit this page soon!',
  TRY_AGAIN_LATER: 'Please try again later.',
};

export const ERRORS = {
  REQUEST_ERROR: 'There was an error with your request. ' + COMMON_PARTS.TRY_AGAIN_LATER,
  REQUEST_NOT_COMPLETED:
    'Something went wrong and request cannot be completed. ' + COMMON_PARTS.WE_ARE_WORKING_ON,
  INFORMATION_NOT_DISPLAYED:
    'An error occurred and the information cannot be displayed. ' + COMMON_PARTS.WE_ARE_WORKING_ON,
};

export const LOGGER_ERRORS = {
  BOUNDARIES: 'An error has been caught in error boundaries!',
  CART_ADD: 'Cart/add error response without message',
};
