import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '../../../components/organisms/ErrorBoundaryDecorator';
import BookingTableListItem from './BookingTableListItem';
import { BookingTableListItemPropTypes } from '../config';

/**
 * Render booking table list
 */
const BookingTableList = ({ bookings, purchasedOffers, ...props }) => {
  if (bookings.length === 0) {
    return <p>No booking within selected filters</p>;
  }

  return (
    <div className="records-list-item__wrapper">
      <div className="records-list-item__details">Offer</div>
      <div className="records-list-item__details">Name</div>
      <div className="records-list-item__details">Nights</div>
      <div className="records-list-item__details">Room Type</div>
      <div className="records-list-item__details">Check In</div>
      <div className="records-list-item__details">Check Out</div>
      <div className="records-list-item__details">Notes</div>
      <div className="records-list-item__details">Vendor Notes</div>
      <div className="records-list-item__details">Meal Plan</div>
      <div className="records-list-item__details">VIP reference number</div>
      <div className="records-list-item__details">Total Cost of booking, USD</div>
      <div className="records-list-item__details">Hotel Confirmation</div>
      <div className="records-list-item__details">Actions</div>
      <div className="records-list-item__details">&nbsp;</div>

      {bookings.map((booking) => (
        <BookingTableListItem
          key={booking.id}
          purchasedOffers={purchasedOffers}
          booking={booking}
          {...props}
        />
      ))}
    </div>
  );
};

BookingTableList.propTypes = {
  purchasedOffers: PropTypes.array.isRequired,
  bookings: PropTypes.array.isRequired,
  ...BookingTableListItemPropTypes,
};

export default ErrorBoundaryDecorator()(BookingTableList);
