import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '../../../components/organisms/ErrorBoundaryDecorator';
import DateRange from '../../../components/molecules/DateRange';
import PopupTitle from '../../../components/atoms/PopupTitle';
import InputField from '../../../components/atoms/InputField';
import Label from '../../../components/atoms/Label';
import Button from '../../../components/atoms/Button';
import { qtyOptions } from '../config';

/**
 * Render inventory update form
 */
const InventoryUpdateForm = ({ days, onSubmitHandler }) => {
  const [qty, setQty] = useState('');
  const [selectedQtyOption, setSelectedQtyOption] = useState('available');
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  /**
   * Handle onChange event for qty option radio field
   *
   * @param event {object}
   */
  const handleQtyOptionChange = (event) => {
    const { name } = event.target;

    let updatedQty = qty;

    if (name === 'available') {
      updatedQty = null;
    }

    if (name === 'not_available') {
      updatedQty = 0;
    }

    setQty(updatedQty);

    setSelectedQtyOption(name);
  };

  /**
   * Handles onRangeSelected event of <DateRange> component
   *
   * @param from {object}
   * @param to {object}
   */
  const handleRangeSelected = (from, to) => {
    setDateFrom(from);
    setDateTo(to);
  };

  /**
   * Handles onSubmit event of form
   *
   * @param event {object}
   */
  const onSubmit = (event) => {
    event.preventDefault();

    onSubmitHandler({ to: dateTo, from: dateFrom }, qty);
  };

  useEffect(() => {
    setDateFrom(days.from);
    setDateTo(days.to);
  }, []);

  return (
    <div className="inventory-page-update-form">
      <PopupTitle>Update inventory</PopupTitle>

      <form onSubmit={onSubmit} className="inventory-update-form__inner">
        <DateRange from={dateFrom} to={dateTo} onRangeSelected={handleRangeSelected} />

        {qtyOptions.map((option) => {
          const { title, key } = option;

          return (
            <Label labelContent={title} htmlFor={key} className="radio-wrapper" key={key}>
              <InputField
                type="radio"
                id={key}
                checked={selectedQtyOption === key}
                onChange={handleQtyOptionChange}
              />

              <span className="checkmark">&nbsp;</span>

              {option.key === 'custom' && (
                <InputField
                  id={key}
                  type="number"
                  min={0}
                  className="input-text"
                  value={qty || 0}
                  disabled={selectedQtyOption !== 'custom'}
                  onChange={(event) => setQty(+event.target.value)}
                />
              )}
            </Label>
          );
        })}

        <Button type="submit" className="inventory-page-update-form__button btn-submit">
          Save
        </Button>
      </form>
    </div>
  );
};

InventoryUpdateForm.propTypes = {
  days: PropTypes.object.isRequired,
  onSubmitHandler: PropTypes.func,
};

InventoryUpdateForm.defaultProps = {
  onSubmitHandler: () => {},
};

export default ErrorBoundaryDecorator()(InventoryUpdateForm);
