/**
 * Booking status model
 */
class BookingStatusModel {
  title = null;
  value = null;
  key = null;

  /**
   * Fill BookingStatus object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.title = item.title;
    this.value = item.value;
    this.key = item.key;
  }
}

export default BookingStatusModel;
