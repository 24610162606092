import PropTypes from 'prop-types';

export const allowedFileTypes = null; // TODO: file type for csv, xls, xlsx
export const maxFileSize = 10; // MB
export const acceptInput = '.pdf, .doc, .docx';

export const BookingTableListItemPropTypes = {
  setCommentHandler: PropTypes.func.isRequired,
  openConfirmationModalHandler: PropTypes.func.isRequired,
  openCancellationModalHandler: PropTypes.func.isRequired,
  openAddPaymentModalHandler: PropTypes.func.isRequired,
  markAsPaidHandler: PropTypes.func.isRequired,
};
