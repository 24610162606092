import React, { useState, useEffect, useContext, useTransition } from 'react';
import _ from 'lodash';

import { getBookingsRequestAPI } from '../utils/api/bookingsAPI';
import { getPurchasedOffersRequestAPI } from '../utils/api/offersAPI';
import VendorsSelectedDataContext from './VendorsSelectedDataContext';
import Spinner from '../components/molecules/Spinner';

const BookingsContext = React.createContext({});

export const BookingsContextProvider = ({ children }) => {
  const [allRoomTypes, setAllRoomTypes] = useState(null);
  const [bookings, setBookings] = useState(null);
  const [purchasedOffers, setPurchasedOffers] = useState(null);
  const [filteredBookings, setFilteredBookings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [bookingSearchQuery, setBookingSearchQuery] = useState('');
  const [bookingSortByDateOption, setBookingSortByDateOption] = useState(null);
  const [selectedOffer, setSelectedOffer] = useState(null);

  const [areBookingsPending, startTransition] = useTransition();

  const { allProperties, selectedVendor, selectedProperty, selectedRoomType } = useContext(
    VendorsSelectedDataContext,
  );

  /**
   * Handles debounced onChange event of BookingSearch
   *
   * @param value {string}
   */
  const onBookingSearchHandler = _.debounce((value) => setBookingSearchQuery(value), 500);

  /**
   * Handles debounced onChange event of BookingSort
   *
   * @param data {object}
   */
  const onBookingSortHandler = _.debounce((data) => setBookingSortByDateOption(data), 1);

  /**
   * Filter bookings by date checkInDate/orderDate
   *
   * @param bookingsData {array}
   *
   * @return {array}
   */
  const searchBookingsBySearchQuery = (bookingsData = filteredBookings) => {
    if (!bookingSearchQuery) {
      return bookingsData;
    }

    return _.filter(bookingsData, (booking) => {
      const holderName = booking.holderName.toLowerCase();
      const bookingNumber = booking.bookingNumber.toLowerCase();
      const query = bookingSearchQuery.toLowerCase();

      return holderName.includes(query) || bookingNumber.includes(query);
    });
  };

  /**
   * Filter bookings by date checkInDate/orderDate
   *
   * @param bookingsData {array}
   *
   * @return {array}
   */
  const sortBookingsByDate = (bookingsData = filteredBookings) => {
    if (!bookingSortByDateOption) {
      return bookingsData;
    }

    const { key, direction } = bookingSortByDateOption;

    const sortedBookings = _.sortBy(bookingsData, key);

    if (direction === 'ASC') {
      sortedBookings.reverse();
    }

    return sortedBookings;
  };

  /**
   * Filter bookings by selected vendor/property/roomType
   *
   * @return {array}
   */
  const filterBookingsByVendorsSelectedData = () => {
    if (!selectedVendor && !selectedProperty && !selectedRoomType && !selectedOffer) {
      return bookings;
    }

    const isFilterAccepted = (data, filter) => {
      if (!data) {
        return false;
      }

      if (!filter) {
        return true;
      }

      return data === filter;
    };

    const isVendorAccepted = (booking) => {
      if (!selectedVendor) {
        return true;
      }

      const bookingPropertyCode = booking.getProperty();

      return !!_.find(selectedVendor.getProperties(), ({ code }) => bookingPropertyCode === code);
    };

    const isOfferAccepted = (booking) =>
      isFilterAccepted(booking.getOfferId(), selectedOffer?.value);

    const isPropertyAccepted = (booking) =>
      isFilterAccepted(booking.getProperty(), selectedProperty?.code);

    const isRoomTypeAccepted = (booking) =>
      isFilterAccepted(booking.getRoomType(), selectedRoomType?.code);

    return _.filter(
      bookings,
      (booking) =>
        isVendorAccepted(booking) &&
        isPropertyAccepted(booking) &&
        isRoomTypeAccepted(booking) &&
        isOfferAccepted(booking),
    );
  };

  /**
   * Generate allRoomTypes from vendors properties
   */
  const generateAllRoomTypes = () => {
    const roomTypesData = [];

    _.each(allProperties, (property) => {
      _.each(property.getRoomTypes(), (roomType) => {
        roomTypesData.push(roomType);
      });
    });

    setAllRoomTypes(roomTypesData);
  };

  /**
   * Load bookings from API
   *
   * @param callback {function}
   */
  const loadBookings = (callback) => {
    if (!callback) {
      setIsLoading(true);
    }

    getBookingsRequestAPI()
      .then((bookingsData) => {
        setBookings(bookingsData);

        callback?.();
      })
      .finally(() => setIsLoading(false));
  };

  /**
   * Load purchased offers from API
   */
  const loadPurchasedOffers = () => {
    getPurchasedOffersRequestAPI().then((purchasedOffersData) => {
      setPurchasedOffers(purchasedOffersData);
    });
  };

  useEffect(() => {
    if (bookings) {
      if (bookingSortByDateOption) {
        startTransition(() => {
          const filteredBookingsByVendorsSelectedData = filterBookingsByVendorsSelectedData();

          const filteredBySearchQueryBookings = searchBookingsBySearchQuery(
            filteredBookingsByVendorsSelectedData,
          );

          const sortedByDateBookings = sortBookingsByDate(filteredBySearchQueryBookings);

          setFilteredBookings(sortedByDateBookings);
        });
      } else {
        setFilteredBookings([]);
      }
    }
  }, [
    bookings,
    selectedVendor,
    selectedProperty,
    selectedRoomType,
    selectedOffer,
    bookingSearchQuery,
    bookingSortByDateOption,
  ]);

  useEffect(() => {
    if (allProperties && bookings) {
      generateAllRoomTypes();
    }
  }, [allProperties, bookings]);

  useEffect(() => {
    loadBookings();
    loadPurchasedOffers();
  }, []);

  return (
    <BookingsContext.Provider
      value={{
        bookings,
        purchasedOffers,
        areBookingsPending,
        filteredBookings,
        allRoomTypes,
        bookingSortByDateOption,
        selectedOffer,
        loadBookings,
        loadPurchasedOffers,
        setIsLoading,
        onBookingSearchHandler,
        onBookingSortHandler,
        setSelectedOffer,
      }}
    >
      {isLoading && <Spinner />}

      {children}
    </BookingsContext.Provider>
  );
};

export default BookingsContext;
