export const allowedFileTypes = null; // TODO: file type for csv, xls, xlsx
export const maxFileSize = 2; // MB
export const acceptInput = '.xlsx, .xls, .csv';

/**
 * List of available import rules
 */
export const importRules = [
  {
    field: 'room type',
    required: true,
    description: 'field should 100% match w/ database value, not case sensitive',
  },
  {
    field: 'date from',
    required: true,
    description: 'date in any possible format (YYYY/mm/dd is default)',
  },
  {
    field: 'date to',
    required: false,
    description: 'date in any possible format (YYYY/mm/dd is default)',
  },
  {
    field: 'available rooms',
    required: true,
    description:
      'field indicates number of available rooms for a specific date,\n' +
      ' acceptable value are:\n' +
      '          -- integers, unsigned\n' +
      '          -- for no limit values lets accept “dash” and “empty cell”\n' +
      '\n' +
      'all other values are considered as invalid',
  },
];

/**
 * List of available qty options for update inventory form
 */
export const qtyOptions = [
  {
    title: 'room is always available',
    key: 'available',
  },
  {
    title: 'room is unavailable',
    key: 'not_available',
  },
  {
    title: 'set available number for room',
    key: 'custom',
  },
];

/**
 * List of available options for export component
 */
export const exportOptions = [
  {
    name: 'Quick export CSV (upcoming only)',
    type: 'upcoming',
    format: 'csv',
  },
  {
    name: 'Quick export Xlsx (upcoming only)',
    type: 'upcoming',
    format: 'xlsx',
  },
  {
    name: 'Quick export CSV (all records)',
    type: 'all',
    format: 'csv',
  },
  {
    name: 'Quick export Xlsx (all records)',
    type: 'all',
    format: 'xlsx',
  },
  /* { TODO:
    name: 'Quick export CSV (custom period)',
    type: 'custom',
    format: 'csv',
  },
  {
    name: 'Quick export Xlsx (custom period)',
    type: 'custom',
    format: 'xlsx',
  }, */
];
