import Echo from 'laravel-echo';

window.io = require('socket.io-client');

const getEchoProxyLogger = (obj = {}) =>
  new Proxy(obj, {
    get(target, name, receiver) {
      if (!Reflect.has(target, name)) {
        return () => getEchoProxyLogger();
      }

      return Reflect.get(target, name, receiver);
    },
  });

/**
 * Returns instance of laravel-echo client
 *
 * @param token {string}
 */
export function getEchoClientInstance(token) {
  try {
    const echo = new Echo({
      broadcaster: 'socket.io',
      host: `//ws.${window.location.host}`,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
      namespace: 'Common.Events',
    });

    return getEchoProxyLogger(echo);
  } catch (e) {
    return getEchoProxyLogger();
  }
}
