import React from 'react';
import { createRoot } from 'react-dom/client';

import Modal from '../components/organisms/Modal';
import Button from '../components/atoms/Button';
import PopupTitle from '../components/atoms/PopupTitle';

const divContainer = document.createElement('div');
divContainer.id = 'custom-modal';

document.body.appendChild(divContainer);

/**
 * Render custom modal
 *
 * @param BtnsComponent {JSX.Element}
 * @param content {string}
 * @param onOk {function}
 * @param onCancel {function}
 * @param onClose {function}
 */
const renderCustomModal = (BtnsComponent, { content, onOk, onCancel, onClose }) => {
  const root = createRoot(divContainer);

  const onUnmountHandler = () => {
    root.unmount();
  };

  const onOkHandler = () => {
    onOk?.();

    onUnmountHandler();
  };

  const onCancelHandler = () => {
    onCancel?.();

    onUnmountHandler();
  };

  const onCloseHandler = () => {
    onClose?.();

    onUnmountHandler();
  };

  root.render(
    <Modal onRequestClose={onCloseHandler} className="custom-modal small-size" isOpen>
      <PopupTitle>{content}</PopupTitle>

      <div className="btns-wrapper">
        <BtnsComponent onOkHandler={onOkHandler} onCancelHandler={onCancelHandler} />
      </div>
    </Modal>,
  );
};

/**
 * Render custom alert modal
 *
 * @param content {string}
 * @param labelOk {string}
 * @param onOk {function}
 * @param onClose {function}
 */
export const customAlert = (content, { labelOk, onOk, onClose }) => {
  // eslint-disable-next-line react/prop-types
  const BtnsComponent = ({ onOkHandler }) => (
    <Button className="btn-submit" onClick={onOkHandler}>
      {labelOk || 'Ok'}
    </Button>
  );

  renderCustomModal(BtnsComponent, { content, onOk, onClose });
};

/**
 * Render custom confirm modal
 *
 * @param content {string}
 * @param labelOk {string}
 * @param labelCancel {string}
 * @param onOk {function}
 * @param onCancel {function}
 * @param onClose {function}
 */
export const customConfirm = (content, { labelOk, labelCancel, onOk, onCancel, onClose }) => {
  // eslint-disable-next-line react/prop-types
  const BtnsComponent = ({ onOkHandler, onCancelHandler }) => (
    <>
      <Button className="btn-submit" onClick={onOkHandler}>
        {labelOk || 'Ok'}
      </Button>

      <Button className="btn-delete" onClick={onCancelHandler}>
        {labelCancel || 'Cancel'}
      </Button>
    </>
  );

  renderCustomModal(BtnsComponent, { content, onOk, onCancel, onClose });
};
