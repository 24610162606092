import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { buttonPropTypes, buttonDefaultProps } from '../../../propTypes/buttonProps';
import Button from '../Button';

/**
 * Button with icon
 */
const ButtonIcon = ({ className, iconSrc, isIconRight, alt, children, ...props }) => (
  <Button
    className={clsx('btn-icon', className, { 'with-icon': iconSrc }, { 'icon-right': isIconRight })}
    {...props}
  >
    {iconSrc && <img src={iconSrc} alt={alt} />}

    {children}
  </Button>
);

ButtonIcon.propTypes = {
  ...buttonPropTypes,
  iconSrc: PropTypes.string.isRequired,
  alt: PropTypes.string,
  isIconRight: PropTypes.bool,
};
ButtonIcon.defaultProps = {
  ...buttonDefaultProps,
  alt: 'icon',
  isIconRight: false,
};

export default ButtonIcon;
