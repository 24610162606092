import React from 'react';

import { ERRORS } from '../../../../enums/errorMessagesEnums';

/**
 * Render error boundaries error message
 *
 * @return {JSX.Element}
 */
const ErrorBoundariesComponent = () => (
  <div className="error-boundaries">{ERRORS.INFORMATION_NOT_DISPLAYED}</div>
);

export default ErrorBoundariesComponent;
