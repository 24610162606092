import React from 'react';
import PropTypes from 'prop-types';

/**
 * Records totals counter information
 */
const RecordsTotals = ({ totalRecords, filteredRecords }) => (
  <div className="records-totals-counter">
    {`Found records:\xa0${
      filteredRecords === null ? totalRecords : filteredRecords
    } (${totalRecords}\xa0total)`}
  </div>
);

RecordsTotals.propTypes = {
  totalRecords: PropTypes.number.isRequired,
  filteredRecords: PropTypes.number,
};

RecordsTotals.defaultProps = {
  filteredRecords: null,
};

export default RecordsTotals;
