import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import VendorsContext from './VendorsContext';
import DropDownItemModel from '../models/DropDownItemModel';
import CustomDropDown from '../components/molecules/CustomDropDown';

const VendorsSelectedDataContext = React.createContext({});

/**
 * Vendors selected data context provider
 */
export const VendorsSelectedDataContextProvider = ({
  isOptionAllEnabled,
  isSelectedVendorEnabled,
  children,
}) => {
  const [allProperties, setAllProperties] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [properties, setProperties] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [roomTypes, setRoomTypes] = useState(null);
  const [selectedRoomType, setSelectedRoomType] = useState(null);

  const { vendors } = useContext(VendorsContext);

  /**
   * Formats object from vendors for usage in <DropDown> component
   *
   * @return {DropDownItemModel[]}
   */
  const formatVendorsForDropDown = () => {
    const allDropDownItem = new DropDownItemModel('All vendors', 'all', '', null);

    if (!vendors) {
      return isOptionAllEnabled ? [allDropDownItem] : [];
    }

    const items = vendors.map(
      (vendor) =>
        new DropDownItemModel(
          vendor.name,
          vendor.name,
          _.join(
            vendor.getProperties().map((property) => property.name),
            ', ',
          ),
          vendor.getLogo(),
        ),
    );

    items.unshift(allDropDownItem);

    return items;
  };

  /**
   * Formats object from properties for usage in <DropDown> component
   *
   * @return {DropDownItemModel[]}
   */
  const formatPropertiesForDropDown = () => {
    const allDropDownItem = new DropDownItemModel('All hotels', 'all', '', null);

    if (!properties) {
      return isOptionAllEnabled ? [allDropDownItem] : [];
    }

    const items = properties.map(
      (property) =>
        new DropDownItemModel(
          property.name,
          property.code,
          _.join(
            property.getRoomTypes().map((roomType) => roomType.name),
            ', ',
          ),
          property.getLogo(),
        ),
    );

    if (isOptionAllEnabled) {
      items.unshift(allDropDownItem);
    }

    return items;
  };

  /**
   * Formats object from room types for usage in <DropDown> component
   *
   * @return {DropDownItemModel[]}
   */
  const formatRoomTypesForDropDown = () => {
    const allDropDownItem = new DropDownItemModel('All room types', 'all', '', null);

    if (!roomTypes) {
      return isOptionAllEnabled ? [allDropDownItem] : [];
    }

    const items = roomTypes.map(
      (roomType) => new DropDownItemModel(roomType.name, roomType.code, '', roomType.getLogo()),
    );

    if (isOptionAllEnabled) {
      items.unshift(allDropDownItem);
    }

    return items;
  };

  /**
   * Handle debounced onOptionSelected event of vendor <DropDown> component
   *
   * @param name {string}
   */
  const onOptionVendorSelected = _.debounce((name) => {
    const vendor = _.find(vendors || [], { name });

    setSelectedVendor(vendor || null);
  }, 1);

  /**
   * Handle debounced onOptionSelected event of property <DropDown> component
   *
   * @param code {string}
   */
  const onOptionPropertySelected = _.debounce((code) => {
    const property = _.find(properties || [], { code });

    setSelectedProperty(property || null);
  }, 1);

  /**
   * Handle debounced onOptionSelected event of room type <DropDown> component
   *
   * @param code {string}
   */
  const onOptionRoomTypeSelected = _.debounce((code) => {
    const roomType = _.find(roomTypes || [], { code });

    setSelectedRoomType(roomType || null);
  }, 1);

  /**
   * Render vendors <DropDown> component
   *
   * @return {JSX.Element}
   */
  const renderVendorsDropDown = () =>
    (isOptionAllEnabled || properties) && (
      <div className="dropdown__wrapper">
        <CustomDropDown
          options={formatVendorsForDropDown()}
          selectedOption={selectedVendor}
          onOptionSelected={onOptionVendorSelected}
          placeholder="Select Vendor.."
        />
      </div>
    );

  /**
   * Render properties <DropDown> component
   *
   * @return {JSX.Element}
   */
  const renderPropertiesDropDown = () =>
    (isOptionAllEnabled || properties) && (
      <div className="dropdown__wrapper">
        <CustomDropDown
          options={formatPropertiesForDropDown()}
          selectedOption={selectedProperty}
          onOptionSelected={onOptionPropertySelected}
          placeholder="Select Hotel.."
        />
      </div>
    );

  /**
   * Render room types <DropDown> component
   *
   * @return {JSX.Element}
   */
  const renderRoomTypesDropDown = () =>
    (isOptionAllEnabled || properties) && (
      <div className="dropdown__wrapper">
        <CustomDropDown
          options={formatRoomTypesForDropDown()}
          selectedOption={selectedRoomType}
          onOptionSelected={onOptionRoomTypeSelected}
          placeholder="Select room type.."
        />
      </div>
    );

  /**
   * Generate properties from vendors
   */
  const generateProperties = () => {
    const propertiesData = [];

    _.each(vendors, (vendor) => {
      _.each(vendor.getProperties(), (property) => {
        propertiesData.push(property);
      });
    });

    if (!isSelectedVendorEnabled) {
      setProperties(propertiesData);
    }

    setAllProperties(propertiesData);
  };

  useEffect(() => {
    if (vendors && !properties && !isOptionAllEnabled && !isSelectedVendorEnabled) {
      generateProperties();
    }
  });

  useEffect(() => {
    setSelectedRoomType(isOptionAllEnabled ? null : _.first(roomTypes) || null);
  }, [roomTypes]);

  useEffect(() => {
    const selectedRoomTypes = selectedProperty?.getRoomTypes() || null;

    setRoomTypes(selectedRoomTypes);
  }, [selectedProperty]);

  useEffect(() => {
    if (isSelectedVendorEnabled) {
      const selectedProperties = selectedVendor?.getProperties() || null;

      setProperties(selectedProperties);
      setSelectedProperty(null);
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (vendors) {
      if (isSelectedVendorEnabled) {
        setSelectedVendor(null);
      }

      generateProperties();
    }
  }, [vendors]);

  return (
    <VendorsSelectedDataContext.Provider
      value={{
        allProperties,
        properties,
        selectedVendor,
        selectedProperty,
        selectedRoomType,
        renderVendorsDropDown,
        renderPropertiesDropDown,
        renderRoomTypesDropDown,
      }}
    >
      {children}
    </VendorsSelectedDataContext.Provider>
  );
};

VendorsSelectedDataContextProvider.propTypes = {
  isOptionAllEnabled: PropTypes.bool,
  isSelectedVendorEnabled: PropTypes.bool,
};

VendorsSelectedDataContextProvider.defaultProps = {
  isOptionAllEnabled: false,
  isSelectedVendorEnabled: false,
};

export default VendorsSelectedDataContext;
