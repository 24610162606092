import React from 'react';
import PropTypes from 'prop-types';
import LoaderSpinner from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

/**
 * Spinner
 */
const Spinner = (props) => (
  <div className="spinner-wrapper">
    <LoaderSpinner {...props} />
  </div>
);

Spinner.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.number,
};

Spinner.defaultProps = {
  type: 'BallTriangle',
  color: '#589E46',
  height: 75,
  width: 75,
};

export default Spinner;
