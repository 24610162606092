import apiClient from '../../services/apiClient';

/**
 * Send "log" request
 *
 * @param message {string}
 * @param messages {array | object}
 * @param level {string}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendLogRequestAPI = ({ message, messages, level }) =>
  apiClient.post('/logs', {
    message,
    additionalData: messages,
    level,
  });
