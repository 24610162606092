import React from 'react';
import { HashRouter } from 'react-router-dom';

import '../../../services/logger-init';
import { AuthContextProvider } from '../../../context/AuthContext';
import { VendorsContextProvider } from '../../../context/VendorsContext';
import ErrorBoundaryDecorator from '../../organisms/ErrorBoundaryDecorator';
import RoutesElements from './UI/RoutesElements';
import InventoryReports from './UI/InventoryReports';
import Toaster from './UI/Toaster';

/**
 * App
 */
const App = () => (
  <div className="app">
    <div className="container">
      <HashRouter>
        <AuthContextProvider>
          <VendorsContextProvider>
            <RoutesElements />

            <InventoryReports />

            <Toaster />
          </VendorsContextProvider>
        </AuthContextProvider>
      </HashRouter>
    </div>
  </div>
);

export default ErrorBoundaryDecorator(true)(App);
