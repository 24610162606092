import apiClient from '../../services/apiClient';

/**
 * Send "update vendor settings" request
 *
 * @param settings {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendUpdateVendorSettingsRequestAPI = (settings) =>
  apiClient
    .put('vendor-settings', settings)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
