import React from 'react';
import PropTypes from 'prop-types';

/**
 * Render rules list
 */
const RulesList = ({ rules }) => {
  /**
   * Returns string representation of required / optional value
   *
   * @param isRequired {boolean}
   *
   * @returns {string}
   */
  const formatRequiredValue = (isRequired = false) => (isRequired ? '(required)' : '(optional)');

  return (
    <ul className="rules-list">
      {rules.map(({ field, description, required }) => (
        <li key={field + description} className="rules-list__item">
          <strong>{field}</strong>

          <i>{formatRequiredValue(required)}</i>

          <span> - {description}</span>
        </li>
      ))}
    </ul>
  );
};

RulesList.propTypes = {
  rules: PropTypes.array.isRequired,
};

export default RulesList;
