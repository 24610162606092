import React from 'react';
import clsx from 'clsx';
import ReactModal from 'react-modal';

import { ModalPropTypes, ModalDefaultProps } from '../../../propTypes/ModalProps';
import ErrorBoundaryDecorator from '../ErrorBoundaryDecorator';
import Button from '../../atoms/Button';

/**
 * Modal
 */
const Modal = ({ className, isOpen, onRequestClose, withCloseButton, children, ...props }) => {
  ReactModal.setAppElement('#root');

  return (
    <ReactModal
      isOpen={isOpen}
      bodyOpenClassName="noScroll"
      onRequestClose={onRequestClose}
      className={clsx('modal', className)}
      {...props}
    >
      {withCloseButton && (
        <Button className="close-btn" onClick={onRequestClose}>
          &#x2a2f;
        </Button>
      )}

      {children}
    </ReactModal>
  );
};

Modal.propTypes = ModalPropTypes;
Modal.defaultProps = ModalDefaultProps;

export default ErrorBoundaryDecorator()(Modal);
