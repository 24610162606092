import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  getCustomProductsRequestAPI,
  sendUpdateCustomProductInfoRequestAPI,
  sendMarkCustomProductPaidRequestAPI,
} from '../../utils/api/customProductsAPI';
import ErrorBoundaryDecorator from '../../components/organisms/ErrorBoundaryDecorator';
import Modal from '../../components/organisms/Modal';
import AddPaymentForm from '../../components/organisms/AddPaymentForm';
import RecordsTotals from '../../components/molecules/RecordsTotals';
import Spinner from '../../components/molecules/Spinner';
import CustomProductTableList from './UI/CustomProductTableList';

/**
 * Custom products page
 */
const CustomProductsPage = () => {
  const [customProducts, setCustomProducts] = useState(null);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAddPaymentModalOpen, setIsAddPaymentModalOpen] = useState(false);

  /**
   * Handler to open add payment modal
   *
   * @param id {number}
   */
  const openAddPaymentModal = (id) => {
    setSelectedProductId(id);
    setIsAddPaymentModalOpen(true);
  };

  /**
   * Handler to close add payment modal
   */
  const closeAddPaymentModal = () => {
    setSelectedProductId(null);
    setIsAddPaymentModalOpen(false);
  };

  /**
   * Handler tp save total booking cost via API.
   *
   * @param id {number}
   * @param costPrice {number}
   */
  const setTotalCost = _.debounce((id, costPrice) => {
    sendUpdateCustomProductInfoRequestAPI(id, { cost_price: costPrice }).then();
  }, 500);

  /**
   * Load custom products from API
   *
   * @param callback {function}
   */
  const loadCustomProducts = (callback) => {
    if (!callback) {
      setIsLoading(true);
    }

    getCustomProductsRequestAPI()
      .then((customProductsData) => {
        setCustomProducts(customProductsData);

        callback?.();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /**
   * Handler to mark custom product as paid
   *
   * @param id {number}
   */
  const markAsPaid = (id) => {
    setIsLoading(true);

    sendMarkCustomProductPaidRequestAPI(id)
      .then(() => {
        const callback = () => toast.success('Marked as paid!');

        loadCustomProducts(callback);
      })
      .catch((error) => {
        toast.error(error.response.data.message);

        setIsLoading(false);
      });
  };

  /**
   * Handles onSubmit event of form component "payment"
   *
   * @param payload {object}
   */
  const onSubmitAddPaymentFormHandler = (payload) => {
    closeAddPaymentModal();

    setIsLoading(true);

    sendUpdateCustomProductInfoRequestAPI(selectedProductId, payload)
      .then(() => {
        loadCustomProducts(false);
      })
      .catch((error) => {
        setIsLoading(false);

        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    loadCustomProducts();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="custom-products-page full-screen-page">
      <div className="white-block-container">
        <div className="white-block-container__top-bar">
          <RecordsTotals totalRecords={customProducts.length} />
        </div>

        <div className="records-list-container">
          <CustomProductTableList
            customProducts={customProducts}
            openAddPaymentModal={openAddPaymentModal}
            setTotalCost={setTotalCost}
            markAsPaid={markAsPaid}
          />
        </div>

        {selectedProductId && (
          <Modal
            isOpen={isAddPaymentModalOpen}
            onRequestClose={closeAddPaymentModal}
            className="auto-size"
          >
            <AddPaymentForm onSubmit={onSubmitAddPaymentFormHandler} />
          </Modal>
        )}
      </div>
    </div>
  );
};

export default ErrorBoundaryDecorator(true)(CustomProductsPage);
