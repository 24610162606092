import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { toast } from 'react-toastify';

import ErrorBoundaryDecorator from '../../organisms/ErrorBoundaryDecorator';
import ButtonIcon from '../../atoms/ButtonIcon';
import InputField from '../../atoms/InputField';
import SmallSpinner from '../../atoms/SmallSpinner';
import downloadImg from '../../../../images/icons/download.svg';

const FileUploader = ({
  title,
  onFileSelected,
  acceptInput,
  maxFileSize,
  allowedFileTypes,
  className,
  ...rest
}) => {
  const [isFileUploading, setIsFileUploading] = useState(false);

  const inputFileRef = useRef(null);

  /**
   * Validate selected file
   *
   * @param file {object}
   *
   * @return {boolean}
   */
  const validate = (file) => {
    if (maxFileSize !== null && file.size / 1000 / 1000 > maxFileSize) {
      throw new Error(`Selected file is too large. Max file size is ${maxFileSize} MB.`);
    }

    if (allowedFileTypes !== null && !allowedFileTypes.includes(file.type)) {
      throw new Error('Selected file type is not allowed. Please select a valid image file.');
    }

    return true;
  };

  /**
   * Handles onChange event of file input
   *
   * @param e {object}
   */
  const handleInputChange = (e) => {
    const fileInput = e.target;

    if (!fileInput.files.length) {
      return;
    }

    const file = fileInput.files[0];

    try {
      validate(file);
    } catch (error) {
      fileInput.value = '';

      toast.error(error);

      return;
    }

    const callback = () => setIsFileUploading(false);

    setIsFileUploading(true);

    onFileSelected(file, callback);
  };

  return (
    <div className={clsx('file-uploader', className)}>
      <InputField
        id="file-uploader-input"
        ref={inputFileRef}
        type="file"
        accept={acceptInput}
        onChange={handleInputChange}
        hidden
      />

      {isFileUploading ? (
        <SmallSpinner />
      ) : (
        <ButtonIcon
          className="btn-submit"
          onClick={() => inputFileRef.current.click()}
          iconSrc={downloadImg}
          {...rest}
        >
          {title}
        </ButtonIcon>
      )}
    </div>
  );
};

FileUploader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  allowedFileTypes: PropTypes.array,
  maxFileSize: PropTypes.number,
  acceptInput: PropTypes.string,
  onFileSelected: PropTypes.func,
};

FileUploader.defaultProps = {
  className: '',
  title: 'Import / Upload',
  allowedFileTypes: null,
  maxFileSize: null,
  acceptInput: '*',
  onFileSelected: () => {},
};

export default ErrorBoundaryDecorator()(FileUploader);
