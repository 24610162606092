import apiClient from '../../services/apiClient';
import BookingModel from '../../models/BookingModel';
import BookingStatusModel from '../../models/BookingStatusModel';

/**
 * Get "bookings" request
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getBookingsRequestAPI = () =>
  apiClient
    .get('bookings')
    .then((response) => {
      const bookings = [];

      if (response.status === 200) {
        response.data.data.forEach((item) => {
          const booking = new BookingModel();
          booking.fill(item);

          bookings.push(booking);
        });
      }

      return Promise.resolve(bookings);
    })
    .catch((error) => Promise.reject(error));

/**
 * Get "booking by booking number" request
 */
export const getBookingByBookingNumberRequestAPI = (bookingNumber) =>
  apiClient
    .get(`booking/${bookingNumber}`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));

/**
 * Get "booking statuses" request
 *
 * @param isOptionAllEnabled {bool}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getBookingStatusesRequestAPI = (isOptionAllEnabled = true) =>
  apiClient
    .get('booking-statuses')
    .then((response) => {
      const bookingStatuses = [];

      if (response.status === 200) {
        response.data.data.forEach(({ code, name }) => {
          const bookingStatus = new BookingStatusModel();
          bookingStatus.fill({ value: code, title: name, key: 'status' });

          bookingStatuses.push(bookingStatus);
        });
      }
      if (isOptionAllEnabled) {
        const bookingStatusAll = new BookingStatusModel();
        bookingStatusAll.fill({ value: null, title: 'All statuses', key: 'status' });

        bookingStatuses.unshift(bookingStatusAll);
      }

      return Promise.resolve(bookingStatuses);
    })
    .catch((error) => Promise.reject(error));

/**
 * Set "comment|vendor_comment|meal_plan|cost_price|hotel_confirmation" request
 *
 * @param id {number}
 * @param payload {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendUpdateBookingInformationRequestAPI = (id, payload) =>
  apiClient
    .put(`booking/${id}`, payload)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));

/**
 * Set "booking as paid" request
 *
 * @param id {number}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendMarkBookingPaidRequestAPI = (id) =>
  apiClient
    .put(`booking/${id}/mark-paid`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));

/**
 * Set "confirm booking" request
 *
 * @param id {number}
 * @param payload {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendConfirmBookingRequestAPI = (id, payload) =>
  apiClient
    .post(`booking/${id}/confirm`, payload)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));

/**
 * Set "cancel booking" request
 *
 * @param id {number}
 * @param payload {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendCancelBookingRequestAPI = (id, payload) =>
  apiClient
    .post(`booking/${id}/cancel`, payload)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
