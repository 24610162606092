import React, { useState, useContext, useRef } from 'react';

import AuthContext from '../../../context/AuthContext';
import InputField from '../../../components/atoms/InputField';
import Button from '../../../components/atoms/Button';
import SmallSpinner from '../../../components/atoms/SmallSpinner';

/**
 * Render Login form
 */
const LoginForm = () => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);

  const emailInputFieldRef = useRef(null);
  const passwordInputFieldRef = useRef(null);

  const { logInHandler } = useContext(AuthContext);

  /**
   * Handles onSubmit event of form
   *
   * @param e {object}
   */
  const onSubmitHandler = (e) => {
    e.preventDefault();

    const callback = () => setIsFormSubmitting(false);

    setIsFormSubmitting(true);

    logInHandler(
      {
        email: emailInputFieldRef.current.value,
        password: passwordInputFieldRef.current.value,
      },
      callback,
    );
  };

  return (
    <div className="login-page__form">
      <h1 className="login-page__form-title">Vendor Portal Login</h1>

      <form onSubmit={onSubmitHandler}>
        <InputField
          ref={emailInputFieldRef}
          id="login-form-email-field"
          type="email"
          placeholder="email"
          className="input-text"
          isInputUncontrolled
        />

        <InputField
          ref={passwordInputFieldRef}
          id="login-form-password-field"
          type="password"
          placeholder="password"
          className="input-text"
          isInputUncontrolled
        />

        {isFormSubmitting ? (
          <SmallSpinner />
        ) : (
          <Button type="submit" className="btn-submit">
            Log in
          </Button>
        )}
      </form>
    </div>
  );
};

export default LoginForm;
