/**
 * VendorSettings model
 */
class VendorSettingsModel {
  key = null;
  value = null;

  /**
   * Fill VendorSettings object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.key = item.key;
    this.value = item.value;
  }
}

export default VendorSettingsModel;
