import apiClient from '../../services/apiClient';
import UserModel from '../../models/UserModel';

/**
 * Send "log in" request
 *
 * @param payload {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendLogInRequestAPI = (payload) =>
  apiClient
    .post('/login', payload)
    .then((response) => {
      const user = new UserModel();
      user.fill(response.data.data);

      return Promise.resolve(user);
    })
    .catch((error) => Promise.reject(error));

/**
 * Send "log in via magic link" request
 *
 * @param token {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendLogInViaMagicLinkRequestAPI = (token) =>
  apiClient
    .post('/magic-link', { token })
    .then((response) => {
      const user = new UserModel();
      user.fill(response.data.data);

      return Promise.resolve(user);
    })
    .catch((error) => Promise.reject(error));

/**
 * Send "log out" request
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendLogOutRequestAPI = () =>
  apiClient
    .post('/logout')
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
