/**
 * Get url param using new URLSearchParams()
 *
 * @param param {string}
 * @param hasBase64 {boolean}
 *
 * @return {string}
 */
const getUrlParam = (param, hasBase64 = false) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const res = urlParams.get(param);

  return hasBase64 ? atob(res) : res;
};

export default getUrlParam;
