export const BOOKINGS_PER_PAGE = 50;
export const BOOKINGS_PAGES_SHOWN = 3;

/**
 * List of available options for date filter
 */
export const dateOptions = [
  {
    title: 'All periods',
    key: 'orderDate',
    value: 'all',
  },
  {
    title: 'Today',
    key: 'orderDate',
    value: 'today',
  },
  {
    title: 'Yesterday',
    key: 'orderDate',
    value: 'yesterday',
  },
  {
    title: 'This week',
    key: 'orderDate',
    value: 'week',
  },
  {
    title: 'This month',
    key: 'orderDate',
    value: 'month',
  },
  {
    title: 'This year',
    key: 'orderDate',
    value: 'year',
  },
  /* {
    title: 'Custom period',
    key: 'orderDate',
    value: 'custom',
  }, */
];
