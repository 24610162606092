import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { formatDate } from '../../../utils/dateUtils';
import VendorsSelectedDataContext from '../../../context/VendorsSelectedDataContext';
import BookingsContext from '../../../context/BookingsContext';
import person from '../../../../images/icons/person.svg';

/**
 * Render booking list item
 */
const BookingListItem = ({ booking }) => {
  const {
    status,
    property,
    roomType,
    bookingNumber,
    holderName,
    guests,
    children,
    orderDate,
    checkInDate,
    checkOutDate,
  } = booking;

  const { allProperties } = useContext(VendorsSelectedDataContext);
  const { allRoomTypes } = useContext(BookingsContext);

  const propertyName = _.find(allProperties, { code: property })?.name || null;
  const roomTypeName = _.find(allRoomTypes, { code: roomType })?.name || null;

  /**
   * Formats language for number of guests
   *
   * @param guestsNum {number}
   *
   * @return {string}
   */
  const generateGuestsHint = (guestsNum) => {
    const adults = `${guestsNum} adult${guestsNum > 1 ? 's' : ''}`;
    const kids = children > 0 ? `, ${children} ${children > 1 ? 'children' : 'child'}` : '';

    return adults + kids;
  };

  /**
   * Render icons according to number of guests/children
   *
   * @param guestsNum {number}
   *
   * @return {JSX.Element || null}
   */
  const renderBookingIcons = (guestsNum) => {
    const icons = [];

    for (let i = 0; i < guestsNum; i++) {
      icons.push(<img key={i} src={person} alt="person-icon" className="icon-person" />);
    }

    return icons;
  };

  /**
   * Generate string of formatted checkInDate + checkOutDate
   *
   * @return {string}
   */
  const generateBookingDates = () => {
    if (!checkInDate || !checkInDate) {
      return '00-00-0000 — 00-00-0000';
    }

    return `${formatDate(checkInDate)} — ${formatDate(checkOutDate)}`;
  };

  /**
   * Render booking property and room type info
   *
   * @return {JSX.Element || null}
   */
  const renderBookingPropertyAndRoomType = () => {
    if (!propertyName && !roomTypeName) {
      return null;
    }

    return (
      <div className="list-item__line property-details">
        <span>{propertyName}&nbsp;</span>
        <span>—&nbsp;{roomTypeName}</span>
      </div>
    );
  };

  return (
    <div className="booking-list__item">
      <div className="list-item__wrapper">
        <div className="list-item__line">
          <div className="list-item__details booking-number" title={bookingNumber}>
            <CopyToClipboard
              text={bookingNumber}
              onCopy={() => {
                toast.success('Copied!');
              }}
            >
              <span className=" booking-number__text">{bookingNumber}</span>
            </CopyToClipboard>
            <em className="order-date"> (booked on: {formatDate(orderDate)})</em>
          </div>

          <div className="list-item__details booking-persons">
            <div className="booking-holder-name" title={holderName}>
              <CopyToClipboard
                text={holderName}
                onCopy={() => {
                  toast.success('Copied!');
                }}
              >
                <span>{holderName}</span>
              </CopyToClipboard>
            </div>

            <div className="booking-guests" title={generateGuestsHint(guests)}>
              <div className="booking-guests__inner">
                {renderBookingIcons(guests)}

                <span className="small-icons">{renderBookingIcons(children)}</span>
              </div>
            </div>
          </div>

          <div className="list-item__details booking-dates">{generateBookingDates()}</div>

          <div className={`list-item__details booking-status__${status.title.toLowerCase()}`}>
            {status.title}
          </div>
        </div>

        {renderBookingPropertyAndRoomType()}
      </div>
    </div>
  );
};

BookingListItem.propTypes = {
  booking: PropTypes.object.isRequired,
};

export default BookingListItem;
