/**
 * Inventory model
 */
class InventoryModel {
  date = '';
  qty = 0;

  /**
   * Fill Inventory object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.date = item.date;
    this.qty = item.qty;
  }
}

export default InventoryModel;
