import apiClient from '../../services/apiClient';
import CartItemModel from '../../models/CartItemModel';

/**
 * Get "custom products" request
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getCustomProductsRequestAPI = () =>
  apiClient
    .get('cart-items')
    .then((response) => {
      const cartItems = [];

      if (response.status === 200) {
        response.data.data.forEach((item) => {
          const cartItem = new CartItemModel();
          cartItem.fill(item);

          cartItems.push(cartItem);
        });
      }

      return Promise.resolve(cartItems);
    })
    .catch((error) => Promise.reject(error));

/**
 * Send "update custom product" request
 *
 * @param id {number}
 * @param payload {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendUpdateCustomProductInfoRequestAPI = (id, payload) =>
  apiClient
    .put(`cart-item/${id}`, payload)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));

/**
 * Send "mark custom product as paid" request
 *
 * @param id {number}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendMarkCustomProductPaidRequestAPI = (id) =>
  apiClient
    .put(`cart-item/${id}/mark-paid`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
