import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { sendUploadVendorVoucherFileRequestAPI } from '../../../utils/api/uploadFileAPI';
import ErrorBoundaryDecorator from '../../../components/organisms/ErrorBoundaryDecorator';
import FileUploader from '../../../components/molecules/FileUploader';
import InputLabelField from '../../../components/molecules/InputLabelField';
import PopupTitle from '../../../components/atoms/PopupTitle';
import Label from '../../../components/atoms/Label';
import Button from '../../../components/atoms/Button';
import SmallSpinner from '../../../components/atoms/SmallSpinner';
import { acceptInput, maxFileSize, allowedFileTypes } from '../config';

/**
 * Render booking confirmation form
 */
const BookingConfirmationForm = ({ booking, onSubmit, onUpload, onUploadFailed }) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [hotelConfirmation, setHotelConfirmation] = useState(booking.hotelConfirmation || '');

  const isSubmitDisabled =
    hotelConfirmation.trim().length < 1 || hotelConfirmation === booking.hotelConfirmation;

  /**
   * Handles onFileSelected event for <FileUploader> component
   *
   * @param file {object}
   * @param callback {function}
   */
  const handleFileSelected = (file, callback) => {
    sendUploadVendorVoucherFileRequestAPI(file, booking.id)
      .then(() => {
        onUpload();
      })
      .catch((error) => {
        onUploadFailed(error);
      })
      .finally(() => {
        callback();
      });
  };

  /**
   * Handles onSubmit event for form
   *
   * @param event {object}
   */
  const onSubmitHandler = (event) => {
    event.preventDefault();

    setIsFormSubmitting(true);

    const callbackFailedSubmit = () => {
      setIsFormSubmitting(false);
    };

    onSubmit({ hotel_confirmation: hotelConfirmation }, callbackFailedSubmit);
  };

  return (
    <>
      <PopupTitle>Confirm booking</PopupTitle>

      <form onSubmit={onSubmitHandler} className="booking-confirmation-form">
        <InputLabelField
          labelContent="Hotel confirmation code *:"
          id="hotel-confirmation"
          type="text"
          className="input-text"
          value={hotelConfirmation}
          onChange={(e) => setHotelConfirmation(e.target.value)}
          isLabelFirst
        />

        <div className="input-label-field">
          <Label labelContent="Voucher file:" htmlFor="upload-file" />

          <FileUploader
            maxFileSize={maxFileSize}
            allowedFileTypes={allowedFileTypes}
            acceptInput={acceptInput}
            onFileSelected={handleFileSelected}
          />
        </div>

        <em>
          please upload a voucher in one of the following formats:&nbsp;
          <strong>{acceptInput}</strong> (Up to 5 MB)
        </em>

        {isFormSubmitting ? (
          <SmallSpinner />
        ) : (
          <Button className="btn-submit" type="submit" disabled={isSubmitDisabled}>
            Confirm
          </Button>
        )}
      </form>
    </>
  );
};

BookingConfirmationForm.propTypes = {
  booking: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
  onUploadFailed: PropTypes.func,
};

BookingConfirmationForm.defaultProps = {
  onUpload: () => {},
  onUploadFailed: () => {},
};

export default ErrorBoundaryDecorator()(BookingConfirmationForm);
