import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { inputPropTypes, inputDefaultProps } from '../../../propTypes/inputProps';
import InputField from '../../atoms/InputField';
import Label from '../../atoms/Label';

/**
 * Input + label field (forwardRef)
 */
const InputLabelField = forwardRef(({ id, labelContent, isLabelFirst, ...inputProps }, ref) => (
  <div
    className={clsx('input-label-field', {
      'label-first': isLabelFirst,
    })}
  >
    <InputField id={id} ref={ref} {...inputProps} />

    <Label htmlFor={id} labelContent={labelContent} />
  </div>
));

InputLabelField.propTypes = {
  labelContent: PropTypes.string.isRequired,
  isLabelFirst: PropTypes.bool,
  ...inputPropTypes,
};

InputLabelField.defaultProps = {
  isLabelFirst: false,
  ...inputDefaultProps,
};

export default InputLabelField;
