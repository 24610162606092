import moment from 'moment';

/**
 * Format date
 *
 * @param date {Moment}
 * @param format {string}
 *
 * @return {string}
 */
export const formatDate = (date, format = 'YYYY-MM-DD') => moment(date).format(format);
