/**
 * DropDown model
 */
class DropDownItemModel {
  title = '';
  value = null;
  description = '';
  image = null;

  /**
   * DropDownItem constructor.
   *
   * @param title {string}
   * @param value {string}
   * @param description {string}
   * @param image {string}
   */
  constructor(title, value, description, image) {
    this.title = title;
    this.value = value;
    this.description = description;
    this.image = image;
  }
}

export default DropDownItemModel;
