import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import PaymentsContext from '../../../context/PaymentsContext';
import ErrorBoundaryDecorator from '../ErrorBoundaryDecorator';
import AddPaymentForm from '../AddPaymentForm';
import SmallSpinner from '../../atoms/SmallSpinner';
import PaymentsTableList from './UI/PaymentsTableList';

/**
 * Payments screen content (form + list of payments)
 */
const PaymentsScreenContent = ({ isModalMode }) => {
  const {
    payments,
    arePaymentsLoading,
    onSubmitPaymentFormHandler,
    onDeletePaymentHandler,
  } = useContext(PaymentsContext);

  /**
   * Render list of payments
   */
  const renderListOfPayments = () => {
    if (arePaymentsLoading) {
      return <SmallSpinner />;
    }

    return <PaymentsTableList payments={payments} onDeleteHandler={onDeletePaymentHandler} />;
  };

  return (
    <>
      <AddPaymentForm onSubmit={onSubmitPaymentFormHandler} isModalMode={isModalMode} />

      <div className="payments-list">{renderListOfPayments()}</div>
    </>
  );
};

PaymentsScreenContent.propTypes = {
  isModalMode: PropTypes.bool,
};

PaymentsScreenContent.defaultProps = {
  isModalMode: false,
};

export default ErrorBoundaryDecorator()(PaymentsScreenContent);
