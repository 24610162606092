import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './js/components/templates/App';
import './styles/app.scss';

const moment = require('moment-timezone');

moment.tz.setDefault('UTC');

const root = createRoot(document.getElementById('root'));

root.render(<App />);
