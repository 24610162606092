import React from 'react';
import { NavLink } from 'react-router-dom';

import ErrorBoundaryDecorator from '../../../organisms/ErrorBoundaryDecorator';
import { navMenuItemsData } from '../config';

/**
 * Custom link component
 *
 * @param title {string}
 * @param url {string}
 * @param children {JSX.Element || null}
 *
 * @return {JSX.Element}
 */
// eslint-disable-next-line react/prop-types
const Link = ({ title, url, children }) => (
  <li className="navigation__list-item">
    {/* eslint-disable-next-line react/prop-types */}
    <NavLink className={title.toLowerCase()} to={url}>
      {title}

      {children}
    </NavLink>
  </li>
);

/**
 * Render link
 *
 * @param props {object}
 * @param children {JSX.Element || null}
 *
 * @return {JSX.Element}
 */
const renderLink = (props, children = null) => (
  <Link key={props.title} {...props}>
    {children}
  </Link>
);

/**
 * Render links
 *
 * @return {JSX.Element}
 */
const renderLinks = () =>
  navMenuItemsData.map(({ items, ...itemsData }) => {
    const dropdownItems = items?.length ? (
      <ul className="navigation__list navigation__list--dropdown">
        {items.map((dropdownItemsData) => renderLink(dropdownItemsData))}
      </ul>
    ) : null;

    return renderLink(itemsData, dropdownItems);
  });

/**
 * Render navigation
 */
const Navigation = () => (
  <div className="navigation">
    <ul className="navigation__list">{renderLinks()}</ul>
  </div>
);

export default ErrorBoundaryDecorator()(Navigation);
