/**
 * Booking model
 */
class BookingModel {
  id = null;
  offerId = null;
  orderDate = null;
  orderStatus = null;
  isCancelled = false;
  cancelledBy = null;
  bookingNumber = '';
  holderName = '';
  guests = 1;
  children = 0;
  checkInDate = null;
  checkOutDate = null;
  status = null;
  nights = 0;
  notes = '';
  vendorNotes = '';
  hotelConfirmation = null;
  hotelVoucher = null;
  totalBookingCost = 0;
  mealPlan = '';
  is_paid = false;

  /**
   * Property object
   */
  property = null;

  /**
   * RoomType object
   */
  roomType = null;

  /**
   * Fill Booking object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.id = item.id;
    this.offerId = item.of;
    this.orderDate = item.od;
    this.orderStatus = item.os;
    this.isCancelled = item.ic;
    this.cancelledBy = item.cb;
    this.checkInDate = item.ci;
    this.checkOutDate = item.co;

    this.bookingNumber = item.bn;
    this.holderName = item.fln ? item.fln : item.fn + ' ' + item.ln;
    this.guests = item.gs;
    this.children = item.ch;
    this.status = item.st;
    this.nights = item.nt;
    this.comment = item.cm;
    this.vendorComment = item.vcm;
    this.hotelConfirmation = item.hc;
    this.hotelVoucher = item.hv;
    this.totalBookingCost = item.cp;
    this.mealPlan = item.mp;

    this.property = item.pr;
    this.roomType = item.rt;
    this.is_paid = item.ip;
  }

  /**
   * Set Property for Booking
   *
   * @param property {object}
   */
  setProperty(property) {
    this.property = property;
  }

  /**
   * Get property of Booking
   *
   * @return {null | object}
   */
  getProperty() {
    return this.property;
  }

  /**
   * Set RoomType for Booking
   *
   * @param roomType {object}
   */
  setRoomType(roomType) {
    this.roomType = roomType;
  }

  /**
   * Get RoomType of Booking
   *
   * @returns {null | object}
   */
  getRoomType() {
    return this.roomType;
  }

  /**
   * Get OfferId of Booking
   *
   * @returns {null | object}
   */
  getOfferId() {
    return this.offerId;
  }
}

export default BookingModel;
