import _ from 'lodash';

import FileModel from '../../models/FileModel';
import VendorModel from '../../models/VendorModel';
import PropertyModel from '../../models/PropertyModel';
import RoomTypeModel from '../../models/RoomTypeModel';
import VendorSettingsModel from '../../models/VendorSettingsModel';

const DEFAULT_VENDOR_LOGO_DATA = {
  name: 'default-logo.png',
  type: 'file',
  path: '/vendors',
};

/**
 * Get processed logo data
 *
 * @param data {object || null}
 * @param defaultData {object || null}
 *
 * @return {object}
 */

const getProcessedFileModelData = (data, defaultData = null) => {
  const file = new FileModel();
  file.fill(data || defaultData);

  return file;
};

/**
 * Get processed room types data
 *
 * @param roomTypesData {array}
 * @param vendorLogo {object || null}
 *
 * @return {array}
 */

const getProcessedRoomTypes = (roomTypesData, vendorLogo) =>
  _.map(roomTypesData, (roomTypeData) => {
    const roomType = new RoomTypeModel();
    roomType.fill(roomTypeData);

    const { logo } = roomTypeData;

    roomType.setLogo(getProcessedFileModelData(logo, vendorLogo));

    return roomType;
  });

/**
 * Get processed properties data
 *
 * @param propertiesData {array}
 * @param vendorLogo {object || null}
 *
 * @return {array}
 */

const getProcessedProperties = (propertiesData, vendorLogo) =>
  _.map(propertiesData, (propertyData) => {
    const property = new PropertyModel();
    property.fill(propertyData);

    const { logo, room_types: roomTypesData, contract } = propertyData;

    property.setLogo(getProcessedFileModelData(logo, vendorLogo));
    property.setRoomTypes(getProcessedRoomTypes(roomTypesData, vendorLogo));

    if (contract) {
      property.setContract(getProcessedFileModelData(contract));
    }

    return property;
  });

/**
 * Get processed settings data
 *
 * @param settingsData {array || null}
 *
 * @return {array}
 */

const getProcessedSettings = (settingsData) => {
  if (!settingsData) {
    return [];
  }

  return _.map(settingsData, (value, key) => {
    const vendorSettings = new VendorSettingsModel();
    vendorSettings.fill({ value, key });

    return vendorSettings;
  });
};

/**
 * Helper that process vendors from API
 *
 * @param vendors {array}
 *
 * @return {array}
 */

const processVendorsRequest = (vendors) =>
  _.map(vendors, (vendorData) => {
    const vendor = new VendorModel();
    vendor.fill(vendorData);

    const { logo, properties, settings } = vendorData;

    vendor.setLogo(getProcessedFileModelData(logo, DEFAULT_VENDOR_LOGO_DATA));

    vendor.setProperties(getProcessedProperties(properties, vendor.getLogo()));

    vendor.setSettings(getProcessedSettings(settings));

    return vendor;
  });

export default processVendorsRequest;
