import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import _ from 'lodash';

import {
  sendUpdateBookingInformationRequestAPI,
  sendMarkBookingPaidRequestAPI,
  sendConfirmBookingRequestAPI,
  sendCancelBookingRequestAPI,
} from '../../utils/api/bookingsAPI';
import VendorsSelectedDataContext from '../../context/VendorsSelectedDataContext';
import BookingsContext from '../../context/BookingsContext';
import PaymentsContext from '../../context/PaymentsContext';
import ErrorBoundaryDecorator from '../../components/organisms/ErrorBoundaryDecorator';
import BookingsTopBar from '../../components/organisms/BookingsTopBar';
import PaymentsScreenContentModal from '../../components/organisms/PaymentsScreenContentModal';
import Modal from '../../components/organisms/Modal';
import SmallSpinner from '../../components/atoms/SmallSpinner';
import BookingTableList from './UI/BookingTableList';
import BookingConfirmationForm from './UI/BookingConfirmationForm';
import BookingCancellationForm from './UI/BookingCancellationForm';
import CustomDropDown from '../../components/molecules/CustomDropDown';
import DropDownItemModel from '../../models/DropDownItemModel';

/**
 * Bookings full screen page
 */
const BookingsFullScreenPage = () => {
  const [isAPIRequestProcessing, setIsAPIRequestProcessing] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);

  const { renderVendorsDropDown, renderPropertiesDropDown, renderRoomTypesDropDown } = useContext(
    VendorsSelectedDataContext,
  );

  const {
    allRoomTypes,
    filteredBookings,
    loadBookings,
    areBookingsPending,
    purchasedOffers,
    selectedOffer,
    setSelectedOffer,
  } = useContext(BookingsContext);

  const { openAddPaymentModalHandler } = useContext(PaymentsContext);

  /**
   * Formats object from purchased offers for usage in <DropDown> component
   *
   * @return {DropDownItemModel[]}
   */
  const formatPurchasedOffersForDropDown = () => {
    const allDropDownItem = new DropDownItemModel('All offers', 'all', '', null);

    if (!purchasedOffers) {
      return [allDropDownItem];
    }

    const items = purchasedOffers.map(
      (offer) => new DropDownItemModel(offer.title, offer.id, '', null),
    );

    items.unshift(allDropDownItem);

    return items;
  };

  /**
   * Debounced handler to save comment/vendor_comment/meal_plan/cost_price/hotel_confirmation via API
   *
   * @param id {number}
   * @param key {string}
   * @param value {string}
   */
  const setCommentHandler = _.debounce((id, key, value) => {
    sendUpdateBookingInformationRequestAPI(id, { [key]: value }).then();
  }, 500);

  /**
   * Handler to open confirmation modal
   *
   * @param booking {object}
   */
  const openConfirmationModalHandler = (booking) => {
    setSelectedBooking(booking);
    setIsConfirmationModalOpen(true);
  };

  /**
   * Handler to open cancellation modal
   *
   * @param booking {object}
   */
  const openCancellationModalHandler = (booking) => {
    setSelectedBooking(booking);
    setIsCancellationModalOpen(true);
  };

  /**
   * Handler to close confirmation modal
   */
  const closeConfirmationModalHandler = () => {
    setSelectedBooking(null);
    setIsConfirmationModalOpen(false);
  };

  /**
   * Handler to close cancellation modal
   */
  const closeCancellationModalHandler = () => {
    setSelectedBooking(null);
    setIsCancellationModalOpen(false);
  };

  /**
   * Handles onUpload event of <BookingConfirmationForm> component
   */
  const handleVoucherUpload = () => {
    toast.success('Uploaded!');
  };

  /**
   * Handles onUploadFailed event of <BookingConfirmationForm> component
   */
  const handleVoucherUploadFailed = () => {
    toast.error('Failed. Please, upload a valid file!');
  };

  /**
   * @param {number} offerId
   */
  const onOptionOfferSelected = _.debounce((offerId) => {
    let offer = _.find(purchasedOffers || [], { id: offerId });

    if (offer) {
      offer = new DropDownItemModel(offer.title, offer.id, '', null);
    }

    setSelectedOffer(offer || null);
  });

  /**
   * Confirm selected booking via API.
   *
   * @param payload {object}
   * @param callback {function}
   */
  const confirmBooking = (payload, callback) => {
    setIsAPIRequestProcessing(true);

    sendConfirmBookingRequestAPI(selectedBooking.id, payload)
      .then(() => {
        const callbackSuccess = () => {
          setTimeout(() => {
            toast.success('Updated!');

            closeConfirmationModalHandler();

            setIsAPIRequestProcessing(false);
          }, 100);
        };

        loadBookings(callbackSuccess);
      })
      .catch((error) => {
        toast.error(error.response.data.message);

        setIsAPIRequestProcessing(false);

        callback();
      });
  };

  /**
   * Cancel selected booking via API.
   *
   * @param payload {object}
   * @param callback {function}
   */
  const cancelBooking = (payload, callback) => {
    setIsAPIRequestProcessing(true);

    sendCancelBookingRequestAPI(selectedBooking.id, payload)
      .then(() => {
        const callbackSuccess = () => {
          setTimeout(() => {
            toast.success('Booking canceled!');

            setIsAPIRequestProcessing(false);

            closeCancellationModalHandler();
          }, 100);
        };

        loadBookings(callbackSuccess);
      })
      .catch((error) => {
        toast.error(error.response.data.message);

        setIsAPIRequestProcessing(false);

        callback();
      });
  };

  /**
   * Mark booking as paid via API
   *
   * @param id {number}
   */
  const markAsPaidHandler = (id) => {
    setIsAPIRequestProcessing(true);

    const callback = () => {
      setTimeout(() => {
        toast.success('Marked as paid!');

        setIsAPIRequestProcessing(false);
      }, 100);
    };

    sendMarkBookingPaidRequestAPI(id)
      .then(() => {
        loadBookings(callback);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  if (!allRoomTypes || !filteredBookings) {
    return null;
  }

  return (
    <div className="bookings-full-screen-page full-screen-page 2222">
      <div className="dropdowns-container">
        {renderVendorsDropDown()}

        {renderPropertiesDropDown()}

        {renderRoomTypesDropDown()}

        <div className="dropdown__wrapper">
          <CustomDropDown
            options={formatPurchasedOffersForDropDown()}
            selectedOption={selectedOffer}
            onOptionSelected={onOptionOfferSelected}
            placeholder="Select an offer.."
          />
        </div>
      </div>

      <div className="white-block-container">
        <BookingsTopBar filteredBookingsData={filteredBookings} />

        <div className="records-list-container">
          {areBookingsPending || isAPIRequestProcessing ? (
            <SmallSpinner />
          ) : (
            <BookingTableList
              purchasedOffers={purchasedOffers}
              bookings={filteredBookings}
              setCommentHandler={setCommentHandler}
              markAsPaidHandler={markAsPaidHandler}
              openConfirmationModalHandler={openConfirmationModalHandler}
              openCancellationModalHandler={openCancellationModalHandler}
              openAddPaymentModalHandler={openAddPaymentModalHandler}
            />
          )}
        </div>
      </div>

      {selectedBooking && isConfirmationModalOpen && (
        <Modal
          isOpen={isConfirmationModalOpen}
          onRequestClose={closeConfirmationModalHandler}
          shouldCloseOnEsc={!isAPIRequestProcessing}
          withCloseButton={!isAPIRequestProcessing}
          shouldCloseOnOverlayClick={!isAPIRequestProcessing}
        >
          <BookingConfirmationForm
            booking={selectedBooking}
            onSubmit={confirmBooking}
            onUpload={handleVoucherUpload}
            onUploadFailed={handleVoucherUploadFailed}
          />
        </Modal>
      )}

      {selectedBooking && isCancellationModalOpen && (
        <Modal
          isOpen={isCancellationModalOpen}
          onRequestClose={closeCancellationModalHandler}
          shouldCloseOnEsc={!isAPIRequestProcessing}
          withCloseButton={!isAPIRequestProcessing}
          shouldCloseOnOverlayClick={!isAPIRequestProcessing}
        >
          <BookingCancellationForm onSubmit={cancelBooking} />
        </Modal>
      )}

      <PaymentsScreenContentModal />
    </div>
  );
};

export default ErrorBoundaryDecorator(true)(BookingsFullScreenPage);
