import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

import ErrorBoundaryDecorator from '../../organisms/ErrorBoundaryDecorator';

const DateRange = ({ from, to, onRangeSelected }) => {
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const dateToRef = useRef(null);

  /**
   * Handles onDayChange event of <DayPickerInput> "from"
   *
   * @param date {object}
   */
  const handleFromChange = (date) => {
    setDateFrom(dateFrom);

    if (date.getTime() > dateTo.getTime()) {
      setDateTo(date);
    }

    dateToRef.current.handleInputFocus();
    dateToRef.current.handleMonthChange(date);
  };

  /**
   * Handles onDayChange event of <DayPickerInput> "to"
   *
   * @param date {object}
   */
  const handleToChange = (date) => {
    setDateTo(date);

    onRangeSelected(dateFrom, date);
  };

  const modifiers = {
    selectedDays: [dateFrom, { from: dateFrom, to: dateTo }],
    numberOfMonths: 2,
  };

  useEffect(() => {
    setDateFrom(from);
    setDateTo(to);
  }, [from, to]);

  return (
    <div className="date-range">
      <DayPickerInput
        format="DD-MM-YYYY"
        value={dateFrom}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          modifiers,
          onDayClick: () => dateToRef.current.getInput().focus(),
        }}
        onDayChange={handleFromChange}
      />

      <span className="date-range__dash"> &mdash; </span>

      <DayPickerInput
        ref={(node) => (dateToRef.current = node)}
        format="DD-MM-YYYY"
        value={dateTo}
        formatDate={formatDate}
        parseDate={parseDate}
        dayPickerProps={{
          disabledDays: { before: dateFrom },
          modifiers,
        }}
        onDayChange={handleToChange}
      />
    </div>
  );
};

DateRange.propTypes = {
  from: PropTypes.instanceOf(Date).isRequired,
  to: PropTypes.instanceOf(Date).isRequired,
  onRangeSelected: PropTypes.func.isRequired,
};

export default ErrorBoundaryDecorator()(DateRange);
