import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { VendorsSelectedDataContextProvider } from '../../../../context/VendorsSelectedDataContext';
import { BookingsContextProvider } from '../../../../context/BookingsContext';
import { PaymentsContextProvider } from '../../../../context/PaymentsContext';
import LoginPage from '../../../../pages/LoginPage';
import HomePage from '../../../../pages/HomePage';
import BookingsPage from '../../../../pages/BookingsPage';
import BookingsFullScreenPage from '../../../../pages/BookingsFullScreenPage';
import CustomProductsPage from '../../../../pages/CustomProductsPage';
import ContractPage from '../../../../pages/ContractPage';
import AddPaymentPage from '../../../../pages/AddPaymentPage';
import InventoryPage from '../../../../pages/InventoryPage';
import SettingsPage from '../../../../pages/SettingsPage';
import BookingConfirmationPage from '../../../../pages/BookingConfirmationPage';
import NotFoundPage from '../../../../pages/NotFoundPage';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

/**
 * Render routes elements
 *
 * @return {JSX.Element}
 */
const RoutesElements = () => (
  <Routes>
    <Route
      path="/login"
      element={
        <PublicRoute restricted hideNavigation>
          <LoginPage />
        </PublicRoute>
      }
    />

    <Route
      path="/"
      element={
        <PrivateRoute>
          <HomePage />
        </PrivateRoute>
      }
    />

    <Route
      path="/sales/bookings"
      element={
        <PrivateRoute>
          <VendorsSelectedDataContextProvider isOptionAllEnabled isSelectedVendorEnabled>
            <BookingsContextProvider>
              <BookingsPage />
            </BookingsContextProvider>
          </VendorsSelectedDataContextProvider>
        </PrivateRoute>
      }
    />

    <Route
      path="/sales/bookings-full-screen"
      element={
        <PrivateRoute hideNavigation>
          <VendorsSelectedDataContextProvider isOptionAllEnabled isSelectedVendorEnabled>
            <BookingsContextProvider>
              <PaymentsContextProvider>
                <BookingsFullScreenPage />
              </PaymentsContextProvider>
            </BookingsContextProvider>
          </VendorsSelectedDataContextProvider>
        </PrivateRoute>
      }
    />

    <Route
      path="/sales/custom-products"
      element={
        <PrivateRoute hideNavigation>
          <CustomProductsPage />
        </PrivateRoute>
      }
    />

    <Route
      path="/sales/contract"
      element={
        <PrivateRoute>
          <VendorsSelectedDataContextProvider>
            <ContractPage />
          </VendorsSelectedDataContextProvider>
        </PrivateRoute>
      }
    />

    <Route
      path="/sales/add-payment"
      element={
        <PrivateRoute>
          <VendorsSelectedDataContextProvider>
            <PaymentsContextProvider>
              <AddPaymentPage />
            </PaymentsContextProvider>
          </VendorsSelectedDataContextProvider>
        </PrivateRoute>
      }
    />

    <Route
      path="/inventory"
      element={
        <PrivateRoute>
          <VendorsSelectedDataContextProvider>
            <InventoryPage />
          </VendorsSelectedDataContextProvider>
        </PrivateRoute>
      }
    />

    <Route
      path="/settings"
      element={
        <PrivateRoute>
          <SettingsPage />
        </PrivateRoute>
      }
    />

    <Route
      path="/booking-confirmation"
      element={
        <PrivateRoute hideNavigation>
          <BookingConfirmationPage />
        </PrivateRoute>
      }
    />

    <Route
      path="/*"
      element={
        <PublicRoute>
          <NotFoundPage />
        </PublicRoute>
      }
    />
  </Routes>
);

export default RoutesElements;
