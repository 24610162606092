import React from 'react';
import clsx from 'clsx';

import { buttonPropTypes, buttonDefaultProps } from '../../../propTypes/buttonProps';
import processEmptyProp from '../../../utils/helpers/processEmptyPropHelper';

/**
 * Atom <button>
 */
const Button = ({ className, dataQaId, children, ...props }) => (
  // eslint-disable-next-line react/button-has-type
  <button
    className={clsx('btn', className)}
    {...props}
    {...processEmptyProp('data-qa-id', dataQaId)}
  >
    {children}
  </button>
);

Button.propTypes = buttonPropTypes;
Button.defaultProps = buttonDefaultProps;

export default Button;
