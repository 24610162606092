import React, { useContext } from 'react';

import AuthContext from '../../../../context/AuthContext';
import ErrorBoundaryDecorator from '../../ErrorBoundaryDecorator';
import LogoutForm from './LogoutForm';

/**
 * Render user menu
 */
const UserMenu = () => {
  const { authTokens, logOutHandler } = useContext(AuthContext);

  return (
    <div className="user-menu">
      <div className="user-menu__title">{authTokens.displayName}</div>

      <ul className="user-menu__list">
        <li className="user-menu__list-item">
          <LogoutForm logOutHandler={logOutHandler} />
        </li>
      </ul>
    </div>
  );
};

export default ErrorBoundaryDecorator()(UserMenu);
