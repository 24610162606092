import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { getEchoClientInstance } from '../../../../services/echoClient';
import AuthContext from '../../../../context/AuthContext';
import FileModel from '../../../../models/FileModel';
import ReportModel from '../../../../models/ReportModel';
import ErrorBoundaryDecorator from '../../../organisms/ErrorBoundaryDecorator';
import Modal from '../../../organisms/Modal';
import ImportReport from './ImportReport';
import ExportReport from './ExportReport';

/**
 * Render inventory reports (import/export)
 */
const InventoryReports = () => {
  const [inventoryImportReport, setInventoryImportReport] = useState(null);
  const [isInventoryImportReportOpen, setIsInventoryImportReportOpen] = useState(false);
  const [inventoryExportReport, setInventoryExportReport] = useState(null);
  const [isInventoryExportReportOpen, setIsInventoryExportReportOpen] = useState(false);
  const [isEchoClientActivated, setIsEchoClientActivated] = useState(false);

  const { authTokens } = useContext(AuthContext);

  const navigate = useNavigate();

  /**
   * Handles ProcessImportNotification event of web sockets
   *
   * @param e {object}
   */
  const handleProcessImportNotificationEvent = (e) => {
    const { status } = e;

    const allowedStatuses = ['rejected', 'failed', 'success'];

    if (!allowedStatuses.includes(status)) {
      return;
    }

    if (status === 'rejected') {
      toast.error('Import rejected!');
    }

    if (status === 'failed') {
      toast.error('Import failed!');
    }

    if (status === 'success') {
      toast.success('Import completed!');
    }

    const report = new ReportModel();
    report.fill(e);

    setInventoryImportReport(report);
    setIsInventoryImportReportOpen(true);
  };

  /**
   * Handles ProcessExportNotification event of web sockets
   *
   * @param e {object}
   */
  const handleProcessExportNotificationEvent = (e) => {
    const { status, data } = e;

    const allowedStatuses = ['failed', 'success'];

    if (!allowedStatuses.includes(status)) {
      return;
    }

    if (status === 'failed') {
      toast.error('Export failed!');

      return;
    }

    toast.success('Export completed!');

    const file = new FileModel();
    file.fill({ path: data.file_path });

    const report = new ReportModel();
    report.setFile(file);

    setInventoryExportReport(report);
    setIsInventoryExportReportOpen(true);
  };

  /**
   * Handles onClick event of import report
   */
  const onClickImportHandler = () => {
    setIsInventoryImportReportOpen(false);

    navigate('#/inventory');
  };

  /**
   * Handles onClick event of export report
   */
  const onClickExportHandler = () => {
    setIsInventoryExportReportOpen(false);

    window.open(inventoryExportReport.getFile().path, '_blank');
  };

  useEffect(() => {
    if (authTokens && !isEchoClientActivated) {
      const { apiToken, id } = authTokens;

      getEchoClientInstance(apiToken)
        .channel(`private-App.Employee.${id}`)
        .listen('ProcessImportNotification', handleProcessImportNotificationEvent)
        .listen('ProcessExportNotification', handleProcessExportNotificationEvent);

      setIsEchoClientActivated(true);
    }
  }, [authTokens]);

  return (
    <>
      {inventoryImportReport && authTokens && (
        <Modal
          isOpen={isInventoryImportReportOpen}
          onRequestClose={() => setIsInventoryImportReportOpen(false)}
        >
          <ImportReport data={inventoryImportReport} onClickHandler={onClickImportHandler} />
        </Modal>
      )}

      {inventoryExportReport && authTokens && (
        <Modal
          isOpen={isInventoryExportReportOpen}
          onRequestClose={() => setIsInventoryExportReportOpen(false)}
        >
          <ExportReport data={inventoryExportReport} onClickHandler={onClickExportHandler} />
        </Modal>
      )}
    </>
  );
};

export default ErrorBoundaryDecorator()(InventoryReports);
