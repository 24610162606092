import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '../ErrorBoundaryDecorator';
import BreadCrumbs from './UI/BreadCrumbs';
import NotificationIcon from './UI/NotificationIcon';
import UserMenu from './UI/UserMenu';

/**
 * Header for pages
 */
const PageHeader = ({ path }) => (
  <div className="page-header">
    <BreadCrumbs path={path} />

    <NotificationIcon />

    <UserMenu />
  </div>
);

PageHeader.propTypes = {
  path: PropTypes.array.isRequired,
};

export default ErrorBoundaryDecorator()(PageHeader);
