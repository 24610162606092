import React, { useState, useContext, useEffect } from 'react';

import VendorsSelectedDataContext from '../../context/VendorsSelectedDataContext';
import ErrorBoundaryDecorator from '../../components/organisms/ErrorBoundaryDecorator';
import PageHeader from '../../components/organisms/PageHeader';
import ContractContent from './UI/ContractContent';

/**
 * Contract page
 */
const ContractPage = () => {
  const [contract, setContract] = useState(null);

  const { selectedProperty, renderPropertiesDropDown } = useContext(VendorsSelectedDataContext);

  useEffect(() => {
    setContract(selectedProperty?.getContract() || null);
  }, [selectedProperty]);

  return (
    <div className="contract-page">
      <PageHeader path={[selectedProperty?.name || '', 'Contract']} />

      <div className="dropdowns-container">{renderPropertiesDropDown()}</div>

      {selectedProperty && <ContractContent contract={contract} />}
    </div>
  );
};

export default ErrorBoundaryDecorator(true)(ContractPage);
