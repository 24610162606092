import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import {
  getBookingsRequestAPI,
  // getBookingByBookingNumberRequestAPI,
  sendConfirmBookingRequestAPI,
} from '../../utils/api/bookingsAPI';
import getUrlParam from '../../utils/helpers/getUrlParamHelper';
import ErrorBoundaryDecorator from '../../components/organisms/ErrorBoundaryDecorator';
import Spinner from '../../components/molecules/Spinner';
import Button from '../../components/atoms/Button';
import InputField from '../../components/atoms/InputField';
import BookingContent from './UI/BookingContent';
import { allowedStatuses } from './config';

/**
 * Booking confirmation page
 */
const BookingConfirmationPage = () => {
  const [selectedBooking, setSelectedBooking] = useState({});
  const [hotelConfirmationValue, setHotelConfirmationValue] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const isConfirmBtnDisabled =
    (isConfirmed && selectedBooking.hotelConfirmation === hotelConfirmationValue) ||
    hotelConfirmationValue.length === 0;

  /**
   * Confirm selected booking via API.
   */
  const onConfirmHandler = () => {
    setIsLoading(true);

    sendConfirmBookingRequestAPI(selectedBooking.id, { hotel_confirmation: hotelConfirmationValue })
      .then(() => {
        setIsConfirmed(true);

        setSelectedBooking((prevState) => ({
          ...prevState,
          hotelConfirmation: hotelConfirmationValue,
        }));
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => setIsLoading(false));
  };

  /**
   * Load selected booking by booking number from API
   */
  const loadSelectedBooking = () => {
    const bookingNumber = getUrlParam('bookingNumber') || '';

    // TODO: when we have BE part use this method instead of getBookingsRequestAPI()
    // getBookingByBookingNumberRequestAPI(bookingNumber)
    //   .then((selectedBookingData) => {
    //     const { status, hotelConfirmation } = selectedBookingData;
    //
    //     setSelectedBooking(selectedBookingData);
    //
    //     if (status === 'confirmed_by_vendor') {
    //       setHotelConfirmationValue(hotelConfirmation);
    //       setIsConfirmed(true);
    //     }
    //   })
    //   .finally(() => setIsLoading(false));

    getBookingsRequestAPI()
      .then((bookings) => {
        const selectedBookingData =
          bookings?.find((booking) => booking.bookingNumber === bookingNumber) || {};
        const { status, hotelConfirmation } = selectedBookingData;

        setSelectedBooking(selectedBookingData);

        if (status === 'confirmed_by_vendor') {
          setHotelConfirmationValue(hotelConfirmation);
          setIsConfirmed(true);
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    loadSelectedBooking();
  }, []);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="booking-confirmation-page full-screen-page">
      <div className="white-block-container">
        <BookingContent booking={selectedBooking} isConfirmed={isConfirmed} />

        {allowedStatuses.includes(selectedBooking?.status) && (
          <div>
            <InputField
              id="hotel-confirmation-input"
              type="text"
              placeholder="Hotel confirmation"
              value={hotelConfirmationValue}
              onChange={(e) => setHotelConfirmationValue(e.target.value)}
            />

            <Button onClick={onConfirmHandler} disabled={isConfirmBtnDisabled}>
              {isConfirmed ? 'update hotel confirmation' : 'confirm'}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ErrorBoundaryDecorator(true)(BookingConfirmationPage);
