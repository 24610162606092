import React, { forwardRef } from 'react';
import clsx from 'clsx';

import { inputPropTypes, inputDefaultProps } from '../../../propTypes/inputProps';
import processEmptyProp from '../../../utils/helpers/processEmptyPropHelper';

/**
 * Atom <input> (forwardRef)
 */
const InputField = forwardRef(
  ({ id, className, dataQaId, value, isInputUncontrolled, ...props }, ref) => (
    <input
      ref={ref}
      name={id}
      id={id}
      className={clsx('input', className)}
      {...props}
      {...processEmptyProp('value', isInputUncontrolled ? null : value)}
      {...processEmptyProp('data-qa-id', dataQaId)}
    />
  ),
);

InputField.propTypes = inputPropTypes;
InputField.defaultProps = inputDefaultProps;

export default InputField;
