import React from 'react';

/**
 * Not found page
 */
const NotFoundPage = () => (
  <div className="not-found-page">
    <h1>Page Not Found</h1>
  </div>
);

export default NotFoundPage;
