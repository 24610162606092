/**
 * CartItem model
 */
class CartItemModel {
  id = null;
  order_date = null;
  order_number = '';
  title = '';
  status = null;
  user = '';
  cost_price = 0;
  cost_price_invoiced_amount = 0;
  cost_price_invoiced_payment_date = null;
  cost_price_invoiced_payment_method = '';
  cost_price_currency = 'USD';
  is_paid = false;

  /**
   * Fill CartItem object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.id = item.id;
    this.order_date = item.order_date;
    this.order_number = item.order_number;
    this.title = item.title;
    this.status = item.status;
    this.user = item.user;
    this.cost_price = item.cost_price;
    this.cost_price_invoiced_amount = item.cost_price_invoiced_amount;
    this.cost_price_currency = item.cost_price_currency;
    this.cost_price_invoiced_payment_date = item.cost_price_invoiced_payment_date;
    this.cost_price_invoiced_payment_method = item.cost_price_invoiced_payment_method;
    this.cost_price_currency = item.cost_price_currency;
    this.is_paid = item.is_paid;
  }
}

export default CartItemModel;
