import React, { useContext } from 'react';

import PaymentsContext from '../../../context/PaymentsContext';
import PaymentsScreenContent from '../PaymentsScreenContent';
import Modal from '../Modal';

/**
 * Modal payments screen content (form + list of payments)
 */
const PaymentsScreenContentModal = () => {
  const { isAddPaymentModalOpen, closeAddPaymentModalHandler } = useContext(PaymentsContext);

  return (
    <Modal
      className="full-screen-size"
      isOpen={isAddPaymentModalOpen}
      onRequestClose={closeAddPaymentModalHandler}
    >
      <PaymentsScreenContent isModalMode />
    </Modal>
  );
};

export default PaymentsScreenContentModal;
