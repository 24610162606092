import HasLogo from '../mixins/HasLogoMixin';

/**
 * Vendor model
 */
class VendorModel {
  name = '';

  /**
   * File object for RoomType logo
   */
  logo = null;

  /**
   * Array of VendorSettings objects
   */
  settings = [];

  /**
   * Array of Property objects
   */
  properties = [];

  /**
   * Fill vendor object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.name = item.name;
  }

  /**
   * Set settings for Vendor
   *
   * @param settings {object}
   */
  setSettings(settings) {
    this.settings = settings;
  }

  /**
   * Get list of Vendor settings
   *
   * @return {object}
   */
  getSettings() {
    return this.settings;
  }

  /**
   * Set properties for Vendor
   *
   * @param properties {object}
   */
  setProperties(properties) {
    this.properties = properties;
  }

  /**
   * Get list of Vendor properties
   *
   * @return {object}
   */
  getProperties() {
    return this.properties;
  }
}

/**
 * Assigning HasLogo mixin to Vendor model
 */
Object.assign(VendorModel.prototype, HasLogo);

export default VendorModel;
