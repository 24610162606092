import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '../../organisms/ErrorBoundaryDecorator';
import InputField from '../../atoms/InputField';

/**
 * Booking search
 */
const BookingSearch = ({ onSearch, ...props }) => {
  /**
   * Handles onChange event of input search
   *
   * @param event {object}
   */
  const onChangeHandler = (event) => {
    onSearch(event.target.value);
  };

  return (
    <div className="booking-search">
      <InputField
        id="booking-search-input"
        className="booking-search__input input-text"
        type="search"
        onChange={onChangeHandler}
        isInputUncontrolled
        {...props}
      />
    </div>
  );
};

BookingSearch.propTypes = {
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
};

BookingSearch.defaultProps = {
  onSearch: () => {},
  placeholder: 'Search...',
};

export default ErrorBoundaryDecorator()(BookingSearch);
