import React, { useState, useEffect, useContext } from 'react';

import { getVendorsRequestAPI } from '../utils/api/vendorsAPI';
import AuthContext from './AuthContext';
import Spinner from '../components/molecules/Spinner';

const VendorsContext = React.createContext({});

export const VendorsContextProvider = ({ children }) => {
  const [vendors, setVendors] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { authTokens } = useContext(AuthContext);

  /**
   * Load vendors from API
   */
  const loadVendors = () => {
    setIsLoading(true);

    getVendorsRequestAPI()
      .then((vendorsData) => {
        setVendors(vendorsData);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (authTokens && !vendors && !isLoading) {
      loadVendors();
    }
  }, [authTokens]);

  return (
    <VendorsContext.Provider
      value={{
        vendors,
        loadVendors,
      }}
    >
      {isLoading && <Spinner />}

      {children}
    </VendorsContext.Provider>
  );
};

export default VendorsContext;
