import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import Select from 'react-select';

import { getImgPath } from '../../../utils/imageUtils';
import ErrorBoundaryDecorator from '../../organisms/ErrorBoundaryDecorator';

/**
 * Custom drop down
 */
const CustomDropDown = ({ options, selectedOption, placeholder, onOptionSelected }) => {
  const [selected, setSelected] = useState(_.first(options) || null);

  /**
   * Formats value, returned from component, 'all' should be treated as null in parent components.
   *
   * @param value {string || null}
   *
   * @returns {string || null}
   */
  const formatReturnValue = (value) => (value === 'all' ? null : value);

  /**
   * Handles onChange event of <Select> component.
   *
   * @param event {object}
   */
  const onSelectChangeHandler = (event) => {
    onOptionSelected(formatReturnValue(event.value));
  };

  /**
   * Render single item for select.
   *
   * @param innerProps {object}
   * @param innerRef {object}
   * @param data {object}
   *
   * @return {JSX.Element}
   */
  const renderListItem = ({ innerProps, innerRef, data }) => {
    const { image, title, description } = data;

    return (
      <div ref={innerRef} {...innerProps} className="dropdown-list__item">
        {image && (
          <div
            className="item-image"
            style={{ backgroundImage: `url(${getImgPath(image, [], 'w150')})` }}
          />
        )}

        <div className={clsx('item-content', !image && 'no-image')}>
          <h4 title={title} className="item-title">
            {title}
          </h4>

          {description && (
            <p title={description} className="item-desc">
              {description}
            </p>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const predicateFunc = ({ value }) =>
      value === selectedOption?.code ||
      value === selectedOption?.name ||
      value === selectedOption?.value;

    const optionItem = _.find(options, predicateFunc) || null;

    setSelected(optionItem || _.first(options) || null);
  }, [selectedOption]);

  useEffect(() => {
    onOptionSelected(selected?.value || null);
  }, []);

  return (
    <Select
      className={clsx('dropdown', { empty: !options.length })}
      classNamePrefix="dropdown-list"
      onChange={onSelectChangeHandler}
      options={options}
      value={selected}
      components={{ Option: renderListItem }}
      getOptionLabel={(option) => option.title}
      placeholder={placeholder}
    />
  );
};

CustomDropDown.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.object,
  placeholder: PropTypes.string,
  onOptionSelected: PropTypes.func,
};

CustomDropDown.defaultProps = {
  selectedOption: null,
  placeholder: 'Select..',
  onOptionSelected: () => {},
};

export default ErrorBoundaryDecorator()(CustomDropDown);
