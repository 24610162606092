import React from 'react';

import ErrorBoundaryDecorator from '../../components/organisms/ErrorBoundaryDecorator';
import LoginForm from './UI/LoginForm';

/**
 * Login page
 */
const LoginPage = () => (
  <div className="login-page">
    <LoginForm />
  </div>
);

export default ErrorBoundaryDecorator(true)(LoginPage);
