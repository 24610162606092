import apiClient from '../../services/apiClient';

/**
 * Get "purchased offers" request
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getPurchasedOffersRequestAPI = () =>
  apiClient
    .get('offers/purchased')
    .then((response) => {
      let offers = [];

      if (response.status === 200) {
        offers = response.data.data;
      }

      return Promise.resolve(offers);
    })
    .catch((error) => Promise.reject(error));
