import apiClient from '../../services/apiClient';
import FileModel from '../../models/FileModel';

/**
 * Send "upload file on server" request
 *
 * @param data {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendUploadFileRequestAPI = (data) =>
  apiClient
    .request({
      url: 'file',
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      const file = new FileModel();
      file.fill(response.data);

      return Promise.resolve(file);
    })
    .catch((error) => Promise.reject(error));

/**
 * Composes FormData for file according to inventory import data format
 *
 * @param file {object}
 * @param property {object}
 */
export const sendUploadInventoryImportFileRequestAPI = (file, property) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('type', 'sheet');
  formData.append('file_type', 'inventory_import');
  formData.append('entity_type', 'Inventory\\Property');
  formData.append('entity_id', property.id);

  return sendUploadFileRequestAPI(formData)
    .then((fileData) => Promise.resolve(fileData))
    .catch((error) => Promise.reject(error));
};

/**
 * Composes FormData for file according to voucher data format
 *
 * @param file {object}
 * @param id {number}
 */
export const sendUploadVendorVoucherFileRequestAPI = (file, id) => {
  const formData = new FormData();

  formData.append('file', file);
  formData.append('type', 'document');
  formData.append('file_type', 'vendor_voucher');
  formData.append('entity_type', 'Booking');
  formData.append('entity_id', id);

  return sendUploadFileRequestAPI(formData)
    .then((fileData) => Promise.resolve(fileData))
    .catch((error) => Promise.reject(error));
};

/**
 * Send "process inventory import file" request
 *
 * @param id {string}
 */
export const sendProcessInventoryImportFileRequestAPI = (id) =>
  apiClient
    .post(`import/${id}/process`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
