import React, { useContext } from 'react';

import AuthContext from '../../context/AuthContext';
import ErrorBoundaryDecorator from '../../components/organisms/ErrorBoundaryDecorator';
import PageHeader from '../../components/organisms/PageHeader';

/**
 * Home page
 */
const HomePage = () => {
  const { authTokens } = useContext(AuthContext);

  return (
    <div className="home-page">
      <PageHeader path={['Home']} />

      <h1>Welcome {authTokens.displayName} to Vendor Portal!</h1>
    </div>
  );
};

export default ErrorBoundaryDecorator(true)(HomePage);
