/**
 * Data of links for navigation menu
 */
export const navMenuItemsData = [
  {
    title: 'Home',
    url: '/',
    icon: '',
  },
  {
    title: 'Sales',
    url: '#',
    icon: '',
    items: [
      // {
      //   title: 'Bookings',
      //   url: '/sales/bookings',
      //   icon: '',
      // },
      {
        title: 'Bookings',
        url: '/sales/bookings-full-screen',
        icon: '',
      },
      {
        title: 'Custom Items',
        url: '/sales/custom-products',
        icon: '',
      },
      // {
      //   title: 'Contract',
      //   url: '/sales/contract',
      //   icon: '',
      // },
      {
        title: 'Payments',
        url: '/sales/add-payment',
        icon: '',
      },
    ],
  },
  // {
  //   title: 'Inventory',
  //   url: '/inventory',
  //   icon: '',
  // },
  // {
  //   title: 'Settings',
  //   url: '/settings',
  //   icon: '',
  // },
];
