import HasLogo from '../mixins/HasLogoMixin';

/**
 * RoomType model
 */
class RoomTypeModel {
  name = '';
  code = '';

  /**
   * File object for RoomType logo
   */
  logo = null;

  /**
   * Fill RoomType object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.name = item.name;
    this.code = item.code;
  }
}

/**
 * Assigning HasLogo mixin to RoomType model
 */
Object.assign(RoomTypeModel.prototype, HasLogo);

export default RoomTypeModel;
