import React, { useContext } from 'react';

import VendorsSelectedDataContext from '../../context/VendorsSelectedDataContext';
import ErrorBoundaryDecorator from '../../components/organisms/ErrorBoundaryDecorator';
import PageHeader from '../../components/organisms/PageHeader';
import PaymentsScreenContent from '../../components/organisms/PaymentsScreenContent';

/**
 * Add payment page
 */
const AddPaymentPage = () => {
  const { selectedProperty, renderPropertiesDropDown } = useContext(VendorsSelectedDataContext);

  return (
    <div className="add-payment-page">
      <PageHeader path={[selectedProperty?.name || '', 'Add payment']} />

      <div className="dropdowns-container">{renderPropertiesDropDown()}</div>

      {selectedProperty && <PaymentsScreenContent />}
    </div>
  );
};

export default ErrorBoundaryDecorator(true)(AddPaymentPage);
