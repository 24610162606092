import _ from 'lodash';

/**
 * Report model
 */
class ReportModel {
  /// TODO: split to export / import report
  title = '';
  status = null;
  type = null;

  validRecords = 0;
  inValidRecords = 0;

  /**
   * List of errors generated by process
   */
  errors = [];

  /**
   * File object for report related file entity
   */
  file = null;

  /**
   * Fill Report object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.title = item.data.message;
    this.status = item.status;
    this.type = item.type;

    this.validRecords = item.data.result.valid_records;
    this.inValidRecords = item.data.result.invalid_records;

    this.errors = [];

    if (item.data.result.errors) {
      _.each(item.data.result.errors, (qty, error) => {
        this.errors.push({
          error,
          qty,
        });
      });
    }
  }

  /**
   * Set file for Report
   *
   * @param file {object}
   */
  setFile(file) {
    this.file = file;
  }

  /**
   * Get file from Report
   *
   * @return {object}
   */
  getFile() {
    return this.file;
  }
}

export default ReportModel;
