/**
 * User model
 */
class UserModel {
  id = null;
  email = '';
  displayName = '';
  apiToken = '';
  isAdmin = false;

  /**
   * Fill User object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.id = item.id;
    this.email = item.email;
    this.displayName = item.display_name;
    this.apiToken = item.api_token;
    this.isAdmin = item.is_admin;
  }
}

export default UserModel;
