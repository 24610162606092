import React from 'react';
import PropTypes from 'prop-types';

import BookingListItem from './BookingListItem';

/**
 * Render booking list
 */
const BookingList = ({ bookings, bookingStatuses }) => {
  if (bookings.length === 0) {
    return <p>No booking within selected filters</p>;
  }

  return bookings.map((booking) => {
    const currentStatus = bookingStatuses.find(
      (bookingsStatus) => bookingsStatus.value === booking.status,
    );

    return <BookingListItem key={booking.id} booking={{ ...booking, status: currentStatus }} />;
  });
};

BookingList.propTypes = {
  bookings: PropTypes.array.isRequired,
  bookingStatuses: PropTypes.array.isRequired,
};

export default BookingList;
