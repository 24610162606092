import React from 'react';
import PropTypes from 'prop-types';

/**
 * Atom <label>
 */
const Label = ({ labelContent, htmlFor, children, ...props }) => (
  <label htmlFor={htmlFor} {...props}>
    {labelContent}

    {children}
  </label>
);

Label.propTypes = {
  labelContent: PropTypes.string.isRequired,
  htmlFor: PropTypes.string.isRequired,
};

export default Label;
