import React, { useContext, useState, useEffect } from 'react';

import VendorsContext from '../../context/VendorsContext';
import ErrorBoundaryDecorator from '../../components/organisms/ErrorBoundaryDecorator';
import PageHeader from '../../components/organisms/PageHeader';
import Spinner from '../../components/molecules/Spinner';
import SettingsForm from './UI/SettingsForm';

/**
 * Settings page
 */
const SettingsPage = () => {
  const [settings, setSettings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { vendors } = useContext(VendorsContext);

  const renderContent = () => {
    if (!vendors) {
      return null;
    }

    return (
      <div className="white-block-container">
        {settings ? (
          <>
            <h2>Notification settings</h2>

            <SettingsForm
              settings={settings}
              setSettings={setSettings}
              setIsLoading={setIsLoading}
            />
          </>
        ) : (
          <h2 className="no-settings-msg">No settings available</h2>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (vendors) {
      //  TODO: need to discuss: settings doesn't update!
      setSettings(vendors[0].getSettings()[0]);
    }
  }, [vendors]);

  return (
    <div className="settings-page">
      {isLoading && <Spinner />}

      <PageHeader path={['Settings']} />

      {renderContent()}
    </div>
  );
};

export default ErrorBoundaryDecorator(true)(SettingsPage);
