import React from 'react';

import notificationIcon from '../../../../../images/icons/notification.svg';

/**
 * Render notification icon
 */
const NotificationIcon = () => (
  <div className="notification-icon">
    <img src={notificationIcon} alt="notification-icon" />
  </div>
);

export default NotificationIcon;
