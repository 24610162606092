/**
 * File model
 */
class FileModel {
  id = null;
  name = '';
  display_name = '';
  path = '';
  type = '';
  link = '';
  resolutions = [];

  /**
   * Fill File object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.id = item.id;
    this.name = item.name;
    this.display_name = item.display_name;
    this.path = item.path;
    this.type = item.type;
    this.link = item.link;
    this.resolutions = item.resolutions;
  }
}

export default FileModel;
