import axios from 'axios';

import { instanceDefaults } from './config';

/**
 * Handles success responses from API
 *
 * @param response
 *
 * @return {Promise}
 */
const handleSuccessResponse = (response) => Promise.resolve(response);

/**
 * Handles error responses from API
 *
 * @param error
 *
 * @return {Promise}
 */
const handleErrorResponse = (error) => {
  const { response, config } = error;

  if (!response) {
    return Promise.reject(error);
  }

  const { status } = response;

  if (response && config && (status === 401 || status === 429)) {
    document.dispatchEvent(new Event(`unauthenticated status-${status}`));
  }

  return Promise.reject(error);
};

/**
 * Creates instance of axios w/ some custom settings
 */
export const getApiClientInstance = () => {
  const instance = axios.create(instanceDefaults);

  instance.interceptors.response.use(handleSuccessResponse, handleErrorResponse);

  return instance;
};

const apiClient = getApiClientInstance();

export default apiClient;
