/**
 * HasLogo mixin
 */
const HasLogo = {
  /**
   * File object for model logo
   */
  logo: null,

  /**
   * Set logo (File) for Model
   *
   * @param logo {object}
   */
  setLogo(logo) {
    this.logo = logo;
  },

  /**
   * Get logo (File) of Model
   *
   * @returns {null | object}
   */
  getLogo() {
    return this.logo;
  },
};

export default HasLogo;
