import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import Button from '../../atoms/Button';
import ButtonIcon from '../../atoms/ButtonIcon';
import arrowDropdown from '../../../../images/icons/arrow-dropdown.svg';

const DropdownButton = ({ className, options, onClick, text, ...rest }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  return (
    <div className={clsx('dropdown-button', className)}>
      <ButtonIcon
        className="btn-submit"
        onClick={() => setIsMenuVisible((prevState) => !prevState)}
        iconSrc={arrowDropdown}
        isIconRight
        {...rest}
      >
        {text}
      </ButtonIcon>

      <ul className={clsx('dropdown-button__list', { visible: isMenuVisible })}>
        {options
          .filter((option) => option)
          .map((option) => (
            <li className="dropdown-button__list-item" key={option.name}>
              <Button className="list-item__button" onClick={() => onClick(option)}>
                {option.name}
              </Button>
            </li>
          ))}
      </ul>
    </div>
  );
};

DropdownButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  options: PropTypes.array,
  onClick: PropTypes.func,
};

DropdownButton.defaultProps = {
  className: '',
  options: [],
  onClick: () => {},
};

export default DropdownButton;
