import React from 'react';
import PropTypes from 'prop-types';

import Label from '../../atoms/Label';
import InputField from '../../atoms/InputField';

/**
 * Custom switch
 */
const CustomSwitch = ({ label, hintText, onChange, ...props }) => (
  <div className="custom-switch">
    <Label labelContent="" htmlFor="custom-switch" className="custom-switch__wrapper">
      <div className="custom-switch__inner">
        <InputField id="custom-switch" type="checkbox" onChange={onChange} {...props} />

        <span className="custom-switch__slider" />
      </div>

      <span className="custom-switch__title">{label}</span>
    </Label>

    {hintText && <span className="custom-switch__hint">{hintText}</span>}
  </div>
);

CustomSwitch.propTypes = {
  label: PropTypes.string,
  hintText: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

CustomSwitch.defaultProps = {
  label: '',
  hintText: '',
  checked: false,
  onChange: () => {},
};

export default CustomSwitch;
