import moment from 'moment';

import apiClient from '../../services/apiClient';

/**
 * Send "export by provided entity" request
 *
 * @param id {number}
 * @param data {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendExportRequestAPI = (id, data) => {
  const { format, type } = data;

  const processedData = {
    format,
  };

  if (type === 'upcoming') {
    processedData.dates = [moment().format('YYYY-MM-DD')];
  }

  return apiClient
    .post(`export/inventory/${id}/process`, processedData)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
};
