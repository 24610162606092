import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '../../../components/organisms/ErrorBoundaryDecorator';
import CustomProductTableListItem from './CustomProductTableListItem';
import { CustomProductTableListItemPropTypes } from '../config';

/**
 * Render custom product table list
 */
const CustomProductTableList = ({
  customProducts,
  setTotalCost,
  openAddPaymentModal,
  markAsPaid,
}) => {
  if (customProducts.length === 0) {
    return <p>No custom products found.</p>;
  }

  return (
    <div className="records-list-item__wrapper">
      <div className="records-list-item__details">Product Name</div>
      <div className="records-list-item__details">Order Number</div>
      <div className="records-list-item__details">Order Date</div>
      <div className="records-list-item__details">Order Status</div>
      <div className="records-list-item__details">User</div>
      <div className="records-list-item__details">Total Cost Price, USD</div>
      {/* <div className="records-list-item__details">Actual Paid to Vendor, USD</div> */}
      <div className="records-list-item__details">Actions</div>

      {customProducts.map((customProduct) => (
        <CustomProductTableListItem
          key={customProduct.order_number}
          customProduct={customProduct}
          setTotalCost={setTotalCost}
          openAddPaymentModal={openAddPaymentModal}
          markAsPaid={markAsPaid}
        />
      ))}
    </div>
  );
};

CustomProductTableList.propTypes = {
  customProducts: PropTypes.array.isRequired,
  ...CustomProductTableListItemPropTypes,
};

export default ErrorBoundaryDecorator()(CustomProductTableList);
