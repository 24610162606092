/**
 * List of available options for sort component
 *
 * @type {({title: string, key: string, direction: string}|{title: string, key: string, direction: string}|{title: string, key: string, direction: string}|{title: string, key: string, direction: string})[]}
 */
export const sortOptions = [
  {
    title: 'By check-in date (closest first)',
    key: 'checkInDate',
    direction: 'DESC',
  },
  {
    title: 'By check-in date (furthest first)',
    key: 'checkInDate',
    direction: 'ASC',
  },
  {
    title: 'By booking date (newest first)',
    key: 'orderDate',
    direction: 'ASC',
  },
  {
    title: 'By booking date (oldest first)',
    key: 'orderDate',
    direction: 'DESC',
  },
];
