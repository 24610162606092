import React from 'react';
import PropTypes from 'prop-types';

import PaymentsTableListItem from './PaymentsTableListItem';

/**
 * Render payments table list
 */
const PaymentsTableList = ({ payments, onDeleteHandler }) => {
  if (payments.length === 0) {
    return <h2>No payments added</h2>;
  }

  return (
    <>
      <div className="payments-list__item list-item">
        <div className="list-item__details">Added By</div>
        <div className="list-item__details">Amount</div>
        <div className="list-item__details">Payment Method</div>
        <div className="list-item__details">Payment Date</div>
        <div className="list-item__details">&nbsp;</div>
      </div>

      {payments.map((payment) => (
        <PaymentsTableListItem
          key={payment.created_at}
          payment={payment}
          onDeleteHandler={onDeleteHandler}
        />
      ))}
    </>
  );
};

PaymentsTableList.propTypes = {
  payments: PropTypes.array.isRequired,
  onDeleteHandler: PropTypes.func.isRequired,
};

export default PaymentsTableList;
