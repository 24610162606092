import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import processEmptyProp from '../../../utils/helpers/processEmptyPropHelper';

/**
 * Atom <h2> for popup (forms)
 */
const PopupTitle = ({ className, dataQaId, children, ...props }) => (
  <h2
    className={clsx('popup-title', className)}
    {...props}
    {...processEmptyProp('data-qa-id', dataQaId)}
  >
    {children}
  </h2>
);

PopupTitle.propTypes = {
  className: PropTypes.string,
  dataQaId: PropTypes.string,
};

PopupTitle.defaultProps = {
  className: '',
  dataQaId: null,
};

export default PopupTitle;
