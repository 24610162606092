import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../atoms/Button';

/**
 * Render payments table list item
 */
const PaymentsTableListItem = ({ payment, onDeleteHandler }) => {
  const {
    user,
    cost_price_invoiced_amount: paidAmount,
    cost_price_invoiced_payment_method: paymentMethod,
    cost_price_invoiced_payment_date: paymentDate,
  } = payment;

  return (
    <div className="payments-list__item list-item">
      <div className="list-item__details">{user || '-'}</div>
      <div className="list-item__details">{paidAmount || 0} USD</div>
      <div className="list-item__details">{paymentMethod || '-'}</div>
      <div className="list-item__details">{paymentDate || '-'}</div>
      <div className="list-item__details">
        <Button onClick={() => onDeleteHandler(payment)} className="btn-delete">
          Delete
        </Button>
      </div>
    </div>
  );
};

PaymentsTableListItem.propTypes = {
  payment: PropTypes.object.isRequired,
  onDeleteHandler: PropTypes.func.isRequired,
};

export default PaymentsTableListItem;
