import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import ErrorBoundaryDecorator from '../../organisms/ErrorBoundaryDecorator';
import { sortOptions } from './config';

/**
 * Booking sort
 */
const BookingSort = ({ options, onSort, selectedOption, placeholder }) => {
  /**
   * Handles onChange event of <Select> component
   *
   * @param data {object}
   */
  const onChangeHandler = (data) => {
    onSort(data);
  };

  /**
   * Render single item in list
   *
   * @param innerProps {object}
   * @param innerRef {object}
   * @param data {object}
   *
   * @return {JSX.Element}
   */
  const listItem = ({ innerProps, innerRef, data }) => (
    <div ref={innerRef} {...innerProps} className="sort-list__item">
      {data.title}
    </div>
  );

  useEffect(() => {
    onSort(selectedOption || options[0]);
  }, []);

  return (
    <Select
      className="sort"
      classNamePrefix="sort-list"
      value={selectedOption || options[0]}
      onChange={onChangeHandler}
      options={options}
      components={{ Option: listItem }}
      getOptionLabel={(option) => option.title}
      placeholder={placeholder}
    />
  );
};

BookingSort.propTypes = {
  options: PropTypes.array,
  onSort: PropTypes.func,
  selectedOption: PropTypes.object,
  placeholder: PropTypes.string,
};

BookingSort.defaultProps = {
  options: sortOptions,
  onSort: () => {},
  selectedOption: {},
  placeholder: 'Sort by...',
};

export default ErrorBoundaryDecorator()(BookingSort);
