import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * Custom textarea with own state
 */
const CustomTextarea = ({ id, initialValue, setValueHandler }) => {
  const [value, setValue] = useState(initialValue || '');

  const onChangeHandler = (e) => {
    const { value: inputValue } = e.target;

    setValueHandler(id, inputValue);
    setValue(inputValue);
  };

  return <textarea value={value} onChange={onChangeHandler} rows="1" className="input-text" />;
};

CustomTextarea.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setValueHandler: PropTypes.func,
};

CustomTextarea.defaultProps = {
  initialValue: '',
  setValueHandler: () => {},
};

export default CustomTextarea;
