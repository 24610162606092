import React from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../../utils/dateUtils';
import CustomTextarea from '../../../components/molecules/CustomTextarea';
import Button from '../../../components/atoms/Button';
import { CustomProductTableListItemPropTypes } from '../config';

/**
 * Render custom product table list item
 */
const CustomProductTableListItem = ({
  customProduct,
  setTotalCost,
  // openAddPaymentModal,
  markAsPaid,
}) => {
  const {
    id,
    title,
    order_date: orderDate,
    order_number: orderNumber,
    status,
    user,
    cost_price: costPrice,
    // cost_price_invoiced_amount: paidAmount,
    // cost_price_invoiced_payment_method: paymentMethod,
    // cost_price_invoiced_payment_date: paymentDate,
    is_paid: isPaid,
  } = customProduct;

  return (
    <>
      <div className="records-list-item__details">{title}</div>
      <div className="records-list-item__details">{orderNumber}</div>
      <div className="records-list-item__details">{formatDate(orderDate)}</div>
      <div className="records-list-item__details">{status}</div>
      <div className="records-list-item__details">{user}</div>
      <div className="records-list-item__details">
        <CustomTextarea id={id} initialValue={costPrice} setValueHandler={setTotalCost} />
      </div>
      {/* <div className="records-list-item__details"> */}
      {/*  {paidAmount ? ( */}
      {/*    <> */}
      {/*      {paidAmount} USD, paid on {formatDate(paymentDate)}{' '} */}
      {/*      via {paymentMethod} */}
      {/*    </> */}
      {/*  ) : ( */}
      {/*    <Button onClick={() => openAddPaymentModal(id)}> */}
      {/*      add payment */}
      {/*    </Button> */}
      {/*  )} */}
      {/* </div> */}
      <div className="records-list-item__details">
        {isPaid ? <>paid</> : <Button onClick={() => markAsPaid(id)}>mark as paid</Button>}
      </div>
    </>
  );
};

CustomProductTableListItem.propTypes = {
  customProduct: PropTypes.object.isRequired,
  ...CustomProductTableListItemPropTypes,
};

export default CustomProductTableListItem;
