import React from 'react';

import FileModel from '../../../models/FileModel';
import ErrorBoundaryDecorator from '../../../components/organisms/ErrorBoundaryDecorator';
import DropdownButton from '../../../components/molecules/DropdownButton';
import PopupTitle from '../../../components/atoms/PopupTitle';
import RulesList from './RulesList';
import { importRules } from '../config';

/**
 * Import example file stored on s3
 */
const INSTRUCTIONS_FILE = new FileModel();
INSTRUCTIONS_FILE.fill({
  name: 'Import example preview',
  type: 'file',
  link: '/media/inventory/vendor-import-example-code.png',
});

/**
 * Import example file stored on s3
 */
const PREVIEW_XLSX_FILE = new FileModel();
PREVIEW_XLSX_FILE.fill({
  name: 'Import xlsx example',
  type: 'file',
  link: '/media/inventory/vendor-import-example-code.xlsx',
});

/**
 * Import example file stored on s3
 */
const PREVIEW_CSV_FILE = new FileModel();
PREVIEW_CSV_FILE.fill({
  name: 'Import csv example',
  type: 'file',
  link: '/media/inventory/vendor-import-example-code.csv',
});

/**
 * Render instructions
 */
const Instructions = () => {
  /**
   * Opens file located by link in new window
   */
  const dropdownClickHandler = (template) => {
    window.open(template.link, '_blank');
  };

  return (
    <div className="inventory-page-instructions">
      <PopupTitle>Upload / import inventory instructions</PopupTitle>

      <img
        className="image-preview"
        src={INSTRUCTIONS_FILE.link}
        title="screenshot from import file"
        alt="screenshot from import file"
      />

      <RulesList rules={importRules} />

      <DropdownButton
        className="import-btn"
        text="Import template"
        onClick={dropdownClickHandler}
        options={[PREVIEW_XLSX_FILE, PREVIEW_CSV_FILE]}
      />
    </div>
  );
};

export default ErrorBoundaryDecorator()(Instructions);
