import React from 'react';
import PropTypes from 'prop-types';

import FileUploader from '../../../components/molecules/FileUploader';
import DropdownButton from '../../../components/molecules/DropdownButton';
import Button from '../../../components/atoms/Button';
import { acceptInput, allowedFileTypes, exportOptions, maxFileSize } from '../config';

/**
 * Render import + export btns
 */
const BtnsActions = ({ handleFileSelected, handleShowInstruction, handleExportInventory }) => (
  <div className="btns-wrapper">
    <FileUploader
      acceptInput={acceptInput}
      maxFileSize={maxFileSize}
      allowedFileTypes={allowedFileTypes}
      onFileSelected={handleFileSelected}
    />

    <Button
      className="btn-show-instructions"
      title="Show import instructions"
      onClick={handleShowInstruction}
    >
      i
    </Button>

    <DropdownButton
      className="import-btn"
      text="Export / Download"
      onClick={handleExportInventory}
      options={exportOptions}
    />
  </div>
);

BtnsActions.propTypes = {
  handleFileSelected: PropTypes.func.isRequired,
  handleShowInstruction: PropTypes.func.isRequired,
  handleExportInventory: PropTypes.func.isRequired,
};

export default BtnsActions;
