import React from 'react';
import { ToastContainer } from 'react-toastify';

import ErrorBoundaryDecorator from '../../../organisms/ErrorBoundaryDecorator';

/**
 * Render toaster notification
 */
const Toaster = () => (
  <ToastContainer
    position="top-center"
    autoClose={2500}
    closeButton={false}
    style={{ textAlign: 'center' }}
    hideProgressBar
    newestOnTop
  />
);

export default ErrorBoundaryDecorator()(Toaster);
