import React from 'react';
import PropTypes from 'prop-types';

import ReportModel from '../../../../models/ReportModel';
import ErrorBoundaryDecorator from '../../../organisms/ErrorBoundaryDecorator';
import PopupTitle from '../../../atoms/PopupTitle';
import Button from '../../../atoms/Button';

/**
 * Render export report
 */
const ExportReport = ({ data, onClick }) => {
  const { title } = data;

  return (
    <div className="report-wrapper">
      <PopupTitle>Download / export inventory report</PopupTitle>

      <div className="error-title">{title}</div>

      <Button onClick={onClick} className="btn-submit">
        Download
      </Button>
    </div>
  );
};

ExportReport.propTypes = {
  data: PropTypes.instanceOf(ReportModel),
  onClick: PropTypes.func,
};

ExportReport.defaultProps = {
  data: new ReportModel(),
  onClick: () => {},
};

export default ErrorBoundaryDecorator()(ExportReport);
