/**
 * Get image path in storage
 *
 * @param image {object}
 * @param modifiers {array}
 * @param size {string}
 *
 * @return {string}
 */
export const getImgPath = (image, modifiers = [], size = 'w600') => {
  if (image.resolutions) {
    const dimension =
      (typeof modifiers === 'string' ? [modifiers] : modifiers).indexOf('squared') !== -1
        ? 'squared'
        : 'landscape';

    const properResolution = image.resolutions.find(
      ({ resolution, dimension: resDimension, extension }) =>
        resolution === size && resDimension === dimension && extension === 'jpeg',
    );

    if (properResolution?.link) {
      return properResolution.link + '?resolution';
    }

    return '';
  }

  return '';
};
