import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

import { sendUpdateVendorSettingsRequestAPI } from '../../../utils/api/settingsAPI';
import ErrorBoundaryDecorator from '../../../components/organisms/ErrorBoundaryDecorator';
import Switch from '../../../components/molecules/CustomSwitch';

/**
 * Render Settings form
 */
const SettingsForm = ({ settings, setSettings, setIsLoading }) => {
  /**
   * Handles onChange event for input
   *
   * @param e {object}
   */
  const onChangeHandler = (e) => {
    setIsLoading(true);

    const updatedSettings = { ...settings, value: e.target.checked };

    setSettings(updatedSettings);

    sendUpdateVendorSettingsRequestAPI(updatedSettings)
      .then(() => {
        toast.success('Updated!');
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="settings-page__form">
      <Switch
        label="receive email for every booking"
        hintText="if turned on you will receive email about each booking that was made"
        onChange={onChangeHandler}
        checked={settings.value}
      />
    </div>
  );
};

SettingsForm.propTypes = {
  settings: PropTypes.object.isRequired,
  setSettings: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
};

export default ErrorBoundaryDecorator()(SettingsForm);
