import React from 'react';
import PropTypes from 'prop-types';

import { allowedStatuses } from '../config';

/**
 * Render booking content
 */
const BookingContent = ({ booking, isConfirmed }) => {
  if (Object.keys(booking).length === 0) {
    return <h2>Can't find booking by this booking number</h2>;
  }

  if (!allowedStatuses.includes(booking.status)) {
    return <h2>Can't confirm this booking</h2>;
  }

  const { holderName, roomType, nights, checkInDate, checkOutDate } = booking;

  const convertDate = (date) => date.split('T')[0];

  return (
    <>
      <h1>
        Confirm Booking
        <br />
        {isConfirmed && '(confirmed)'}
      </h1>

      <table>
        <tbody>
          <tr>
            <td>Guest:</td>
            <td>{holderName}</td>
          </tr>

          <tr>
            <td>Room:</td>
            <td>{roomType}</td>
          </tr>

          <tr>
            <td>Length of stay:</td>
            <td>{nights} nights</td>
          </tr>

          <tr>
            <td>Check-in:</td>
            <td>{convertDate(checkInDate)}</td>
          </tr>

          <tr>
            <td>Check-out:</td>
            <td>{convertDate(checkOutDate)}</td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

BookingContent.propTypes = {
  booking: PropTypes.object.isRequired,
  isConfirmed: PropTypes.bool.isRequired,
};

export default BookingContent;
