import React, { useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { formatDate } from '../../../utils/dateUtils';
import BookingsContext from '../../../context/BookingsContext';
import CustomTextarea from '../../../components/molecules/CustomTextarea';
import Button from '../../../components/atoms/Button';
import { BookingTableListItemPropTypes } from '../config';

/**
 * Render booking table list item
 */
const BookingTableListItem = ({
  purchasedOffers,
  booking,
  setCommentHandler,
  openConfirmationModalHandler,
  openCancellationModalHandler,
  openAddPaymentModalHandler,
  markAsPaidHandler,
}) => {
  const {
    id: bookingId,
    offerId,
    orderStatus,
    holderName,
    checkInDate,
    checkOutDate,
    nights,
    comment,
    vendorComment,
    bookingNumber,
    hotelConfirmation,
    hotelVoucher,
    totalBookingCost,
    mealPlan,
    isCancelled,
    cancelledBy,
    is_paid: isPaid,
  } = booking;

  const { allRoomTypes } = useContext(BookingsContext);

  // TODO: we need BE give us roomType.name
  const roomType = _.find(allRoomTypes, { code: booking.roomType })?.name;
  const offer = _.find(purchasedOffers, { id: offerId }) || {};

  const isOrderStatusRefunded = orderStatus === 'refunded';

  const cancelledStatus = 'cancelled by ' + cancelledBy;

  return (
    <>
      <div className="records-list-item__details">{offer.title} [#{offer.id}]</div>

      <div className="records-list-item__details">
        {holderName}

        {isOrderStatusRefunded && (
          <span className={`record-status__text record-status__text--${orderStatus}`}>
            {orderStatus}
          </span>
        )}

        {isCancelled && (
          <span className="record-status__text record-status__text--cancelled">
            {cancelledStatus}
          </span>
        )}
      </div>

      <div className="records-list-item__details">{nights}</div>
      <div className="records-list-item__details">{roomType}</div>

      <div className="records-list-item__details">
        {checkInDate ? formatDate(checkInDate) : 'TBD'}
      </div>

      <div className="records-list-item__details property-details">
        {checkOutDate ? formatDate(checkOutDate) : 'TBD'}
      </div>

      <div className="records-list-item__details">
        <CustomTextarea
          id={bookingId}
          initialValue={comment}
          setValueHandler={(id, value) => setCommentHandler(id, 'comment', value)}
        />
      </div>

      <div className="records-list-item__details">
        <CustomTextarea
          id={bookingId}
          initialValue={vendorComment}
          setValueHandler={(id, value) => setCommentHandler(id, 'vendor_comment', value)}
        />
      </div>

      <div className="records-list-item__details">
        <CustomTextarea
          id={bookingId}
          initialValue={mealPlan}
          setValueHandler={(id, value) => setCommentHandler(id, 'meal_plan', value)}
        />
      </div>

      <div className="records-list-item__details" style={{ whiteSpace: 'nowrap' }}>
        {bookingNumber}
      </div>

      <div className="records-list-item__details">
        <CustomTextarea
          id={bookingId}
          setValueHandler={(id, value) => setCommentHandler(id, 'cost_price', value)}
          initialValue={totalBookingCost}
        />
      </div>

      <div className="records-list-item__details">
        {hotelConfirmation && hotelVoucher && (
          <a href={hotelVoucher} target="_blank" rel="noreferrer">
            {hotelConfirmation}
          </a>
        )}

        {hotelConfirmation && !hotelVoucher && hotelConfirmation}

        {!hotelConfirmation && '(not set)'}
      </div>

      <div className="records-list-item__details">
        {!isCancelled && checkInDate && (
          <>
            <Button onClick={() => openConfirmationModalHandler(booking)}>Confirm</Button>

            <Button onClick={() => openCancellationModalHandler(booking)}>Cancel</Button>
          </>
        )}
      </div>

      <div className="records-list-item__details">
        {isPaid ? (
          <>paid</>
        ) : (
          <Button onClick={() => markAsPaidHandler(booking.id)}>Mark as paid</Button>
        )}

        <Button onClick={() => openAddPaymentModalHandler(booking)}>Add payment</Button>
      </div>
    </>
  );
};

BookingTableListItem.propTypes = {
  purchasedOffers: PropTypes.array.isRequired,
  booking: PropTypes.object.isRequired,
  ...BookingTableListItemPropTypes,
};

export default BookingTableListItem;
