import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  sendUploadInventoryImportFileRequestAPI,
  sendProcessInventoryImportFileRequestAPI,
} from '../../utils/api/uploadFileAPI';
import {
  getInventoryByRoomTypeRequestAPI,
  sendUpdateInventoryByRoomTypeRequestAPI,
} from '../../utils/api/inventoryAPI';
import { sendExportRequestAPI } from '../../utils/api/exportAPI';
import VendorsSelectedDataContext from '../../context/VendorsSelectedDataContext';
import ErrorBoundaryDecorator from '../../components/organisms/ErrorBoundaryDecorator';
import PageHeader from '../../components/organisms/PageHeader';
import Modal from '../../components/organisms/Modal';
import CustomCalendar from '../../components/molecules/CustomCalendar';
import Button from '../../components/atoms/Button';
import BtnsActions from './UI/BtnsActions';
import Instructions from './UI/Instructions';
import InventoryUpdateForm from './UI/InventoryUpdateForm';

/**
 * Inventory page
 */
const InventoryPage = () => {
  const [inventory, setInventory] = useState(null);
  const [showImportInstructions, setShowImportInstructions] = useState(false);
  const [showInventoryUpdateForm, setShowInventoryUpdateForm] = useState(false);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());

  const {
    selectedProperty,
    selectedRoomType,
    renderPropertiesDropDown,
    renderRoomTypesDropDown,
  } = useContext(VendorsSelectedDataContext);

  /**
   * Handles onUpdateSubmitted event from <Calendar> component
   *
   * @param dates {object}
   * @param qty {string}
   */
  const updateInventory = (dates, qty) => {
    if (!selectedRoomType) {
      toast.error('Can`t update inventory!');
    } else {
      const processedData = {
        from: moment(dates.from).format('YYYY-MM-DD'),
        to: moment(dates.to).format('YYYY-MM-DD'),
      };

      sendUpdateInventoryByRoomTypeRequestAPI(selectedRoomType.code, { dates: processedData, qty })
        .then(() => {
          getInventoryByRoomTypeRequestAPI(selectedRoomType.code).then((inventoryData) => {
            setInventory(inventoryData);
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  /**
   * Handles onUpdateSubmitted event for <InventoryUpdateForm> component
   *
   * @param dates {object}
   * @param qty {string}
   */
  const handleUpdateSubmitted = (dates, qty) => {
    setShowInventoryUpdateForm(false);

    updateInventory(dates, qty);

    toast.success('Updated!');
  };

  /**
   * Handler for range selected event
   *
   * @param from {string}
   * @param to {string}
   * @param day {string}
   */
  const handleRangeSelected = (from, to, day) => {
    setDateFrom(from);
    setDateTo(day);

    setShowInventoryUpdateForm((prevState) => !prevState);
  };

  /**
   * Handles onFileSelected event for <FileUploader> component
   *
   * @param file {object}
   * @param callback {function}
   */
  const handleFileSelected = (file, callback) => {
    sendUploadInventoryImportFileRequestAPI(file, selectedProperty)
      .then((fileData) => {
        sendProcessInventoryImportFileRequestAPI(fileData?.id).then(() => {
          toast.success('Uploaded!');

          callback();
        });
      })
      .catch(() => {
        toast.error('Failed. Please, upload a valid import file!');

        callback();
      });
  };

  /**
   * Export inventory by rule, returned from <InventoryExport> component
   *
   * @param type {string}
   * @param format {string}
   */
  const handleExportInventory = ({ type, format }) => {
    if (!selectedProperty) {
      toast.error('Can`t update inventory!');
    } else {
      sendExportRequestAPI(selectedProperty.id, {
        type,
        format,
      })
        .then(() => {
          toast.success('Export initiated!');
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  useEffect(() => {
    setInventory([]);

    if (selectedRoomType) {
      getInventoryByRoomTypeRequestAPI(selectedRoomType.code).then((inventoryData) => {
        setInventory(inventoryData);
      });
    }
  }, [selectedRoomType]);

  return (
    <div className="inventory-page">
      <PageHeader
        path={[selectedProperty?.name || '', selectedRoomType?.name || '', 'Inventory']}
      />

      <div className="dropdowns-container">
        {renderPropertiesDropDown()}

        {renderRoomTypesDropDown()}

        {selectedRoomType && (
          <CopyToClipboard
            text={selectedRoomType.name}
            onCopy={() => {
              toast.success('Copied!');
            }}
          >
            <Button className="btn-copy-to-clipboard" title={selectedRoomType.name} />
          </CopyToClipboard>
        )}
      </div>

      {selectedProperty && (
        <div className="white-block-container">
          <BtnsActions
            handleFileSelected={handleFileSelected}
            handleExportInventory={handleExportInventory}
            handleShowInstruction={() => setShowImportInstructions(true)}
          />

          {selectedRoomType && (
            <CustomCalendar inventory={inventory} onRangeSelected={handleRangeSelected} />
          )}
        </div>
      )}

      <Modal
        isOpen={showImportInstructions}
        onRequestClose={() => setShowImportInstructions(false)}
      >
        <Instructions />
      </Modal>

      <Modal
        isOpen={showInventoryUpdateForm}
        onRequestClose={() => setShowInventoryUpdateForm(false)}
      >
        <InventoryUpdateForm
          onSubmitHandler={handleUpdateSubmitted}
          days={{ from: dateFrom, to: dateTo }}
        />
      </Modal>
    </div>
  );
};

export default ErrorBoundaryDecorator(true)(InventoryPage);
