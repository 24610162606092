import apiClient from '../../services/apiClient';
import InventoryModel from '../../models/InventoryModel';

/**
 * Get "inventory by room type" request
 *
 * @param roomTypeCode {string}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getInventoryByRoomTypeRequestAPI = (roomTypeCode) =>
  apiClient
    .get(`inventory/${roomTypeCode}`)
    .then((response) => {
      // TODO: use ranges / optimised resource to decrease response size
      const inventoryData = [];

      if (response.status === 200) {
        response.data.data.forEach((item) => {
          const inventory = new InventoryModel();
          inventory.fill(item);

          inventoryData.push(inventory);
        });
      }

      return Promise.resolve(inventoryData);
    })
    .catch((error) => Promise.reject(error));

/**
 * Send "update inventory by room type" request
 *
 * @param roomTypeCode {string}
 * @param payload {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export function sendUpdateInventoryByRoomTypeRequestAPI(roomTypeCode, payload) {
  return apiClient
    .put(`inventory/${roomTypeCode}`, payload)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}
