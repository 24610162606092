import HasLogo from '../mixins/HasLogoMixin';

/**
 * Property model
 */
class PropertyModel {
  id = null;
  name = '';
  code = '';

  /**
   * File object for Property logo
   */
  logo = null;

  /**
   * File object for Property contract
   */
  contract = null;

  /**
   * Array of RoomType objects
   */
  roomTypes = [];

  /**
   * Fill Property object w/ data
   *
   * @param item {object}
   */
  fill(item) {
    this.id = item.id;
    this.name = item.name;
    this.code = item.code;
  }

  /**
   * Set contract for Property
   *
   * @param contract {null | object}
   */
  setContract(contract) {
    this.contract = contract;
  }

  /**
   * Get contract of Property
   *
   * @return {null | object}
   */
  getContract() {
    return this.contract;
  }

  /**
   * Set room types for Property
   *
   * @param roomTypes {array}
   */
  setRoomTypes(roomTypes) {
    this.roomTypes = roomTypes;
  }

  /**
   * Get list of Property room types
   *
   * @return {array}
   */
  getRoomTypes() {
    return this.roomTypes;
  }
}

/**
 * Assigning HasLogo mixin to Property model
 */
Object.assign(PropertyModel.prototype, HasLogo);

export default PropertyModel;
