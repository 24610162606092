import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '../../../components/organisms/ErrorBoundaryDecorator';
import InputLabelField from '../../../components/molecules/InputLabelField';
import PopupTitle from '../../../components/atoms/PopupTitle';
import Button from '../../../components/atoms/Button';
import SmallSpinner from '../../../components/atoms/SmallSpinner';

/**
 * Render booking cancellation form
 */
const BookingCancellationForm = ({ onSubmit }) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [cancellationReason, setCancellationReason] = useState('');

  const isSubmitDisabled = cancellationReason.trim().length < 1;

  /**
   * Handles onSubmit event for form
   *
   * @param event {object}
   */
  const onSubmitHandler = (event) => {
    event.preventDefault();

    setIsFormSubmitting(true);

    const callbackFailedSubmit = () => {
      setIsFormSubmitting(false);
    };

    onSubmit({ cancellation_reason: cancellationReason }, callbackFailedSubmit);
  };

  return (
    <>
      <PopupTitle>Cancel booking</PopupTitle>

      <form onSubmit={onSubmitHandler} className="booking-cancellation-form">
        <InputLabelField
          labelContent="Cancellation reason *:"
          id="cancellation-reason"
          type="text"
          className="input-text"
          value={cancellationReason}
          onChange={(e) => setCancellationReason(e.target.value)}
          isLabelFirst
        />

        {isFormSubmitting ? (
          <SmallSpinner />
        ) : (
          <Button className="btn-submit" type="submit" disabled={isSubmitDisabled}>
            Cancel
          </Button>
        )}
      </form>
    </>
  );
};

BookingCancellationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ErrorBoundaryDecorator()(BookingCancellationForm);
