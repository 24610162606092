import React, { useState, useContext, useEffect } from 'react';
import moment from 'moment';
import _ from 'lodash';
import Pagination from 'react-js-pagination';

import { getBookingStatusesRequestAPI } from '../../utils/api/bookingsAPI';
import VendorsSelectedDataContext from '../../context/VendorsSelectedDataContext';
import BookingsContext from '../../context/BookingsContext';
import ErrorBoundaryDecorator from '../../components/organisms/ErrorBoundaryDecorator';
import PageHeader from '../../components/organisms/PageHeader';
import BookingsTopBar from '../../components/organisms/BookingsTopBar';
import Switch from '../../components/molecules/CustomSwitch';
import BookingSort from '../../components/molecules/BookingSort';
import BookingList from './UI/BookingList';
import { dateOptions, BOOKINGS_PAGES_SHOWN, BOOKINGS_PER_PAGE } from './config';

/**
 * Bookings page
 */
const BookingsPage = () => {
  const [additionalFilteredBookings, setAdditionalFilteredBookings] = useState(null);
  const [paginatedBookings, setPaginatedBookings] = useState(null);
  const [activeNumberPage, setActiveNumberPage] = useState(1);
  const [bookingStatuses, setBookingStatuses] = useState(null);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState(null);
  const [selectedBookingPeriodDate, setSelectedBookingPeriodDate] = useState(null);
  const [showPastCheckInBookings, setShowPastCheckInBookings] = useState(true);

  const {
    selectedVendor,
    selectedProperty,
    selectedRoomType,
    renderVendorsDropDown,
    renderPropertiesDropDown,
    renderRoomTypesDropDown,
  } = useContext(VendorsSelectedDataContext);

  const { allRoomTypes, filteredBookings } = useContext(BookingsContext);

  const isDataLoaded =
    filteredBookings && additionalFilteredBookings && bookingStatuses && allRoomTypes;

  /**
   * Filter bookings by passed checkInDate (if true show all)
   *
   * @return {array}
   */
  const filterBookingsByPassedCheckInDate = () => {
    if (showPastCheckInBookings) {
      return filteredBookings;
    }

    const currentDate = moment();

    return _.filter(
      filteredBookings,
      ({ checkInDate }) => currentDate.diff(moment(checkInDate), 'days') <= -1,
    );
  };

  /**
   * Filter bookings by status
   *
   * @param bookingsData {array}
   *
   * @return {array}
   */
  const filterBookingsByStatus = (bookingsData) => {
    const { value } = selectedBookingStatus || {};

    if (!value) {
      return bookingsData;
    }

    return _.filter(bookingsData, ({ status }) => status === value);
  };

  /**
   * Filter bookings by period
   *
   * @param bookingsData {array}
   *
   * @return {array}
   */
  const filterBookingsByPeriod = (bookingsData) => {
    const { value } = selectedBookingPeriodDate || {};

    if (!value) {
      return bookingsData;
    }

    const currentDate = moment();

    return _.filter(bookingsData, ({ orderDate }) => {
      const dateToCompare = moment(orderDate);

      switch (value) {
        case 'today':
          return currentDate.diff(dateToCompare, 'days') === 0;
        case 'yesterday':
          return currentDate.diff(dateToCompare, 'days') === 1;
        case 'week':
          return currentDate.diff(dateToCompare, 'days') <= 7;
        case 'month':
          return currentDate.diff(dateToCompare, 'days') <= 30;
        case 'year':
          return currentDate.diff(dateToCompare, 'days') <= 365;
        default:
          return true;
      }
    });
  };

  /**
   * Creates a slice from filtered bookings according to selected page
   */
  const paginateBookings = () => {
    const startIndex = (activeNumberPage - 1) * BOOKINGS_PER_PAGE;
    const endIndex = startIndex + BOOKINGS_PER_PAGE;

    setPaginatedBookings(_.slice(additionalFilteredBookings, startIndex, endIndex));
  };

  useEffect(() => {
    paginateBookings();
  }, [additionalFilteredBookings, activeNumberPage]);

  useEffect(() => {
    if (filteredBookings) {
      const filteredBookingsByPassedCheckInDate = filterBookingsByPassedCheckInDate(
        filteredBookings,
      );

      const filteredBookingsByStatus = filterBookingsByStatus(filteredBookingsByPassedCheckInDate);

      const filteredBookingsByPeriod = filterBookingsByPeriod(filteredBookingsByStatus);

      setAdditionalFilteredBookings(filteredBookingsByPeriod);

      setActiveNumberPage(1);
    }
  }, [filteredBookings, showPastCheckInBookings, selectedBookingStatus, selectedBookingPeriodDate]);

  useEffect(() => {
    getBookingStatusesRequestAPI().then((bookingStatusesData) => {
      setBookingStatuses(bookingStatusesData);
    });
  }, []);

  return (
    <div className="bookings-page">
      <PageHeader
        path={
          isDataLoaded
            ? [
              selectedVendor?.name || 'All Vendors',
              selectedProperty?.name || 'All Hotels',
              selectedRoomType?.name || 'All Room Types',
              'Bookings',
            ]
            : ['Bookings']
        }
      />

      {isDataLoaded && (
        <>
          <div className="dropdowns-container">
            {renderVendorsDropDown()}

            {renderPropertiesDropDown()}

            {renderRoomTypesDropDown()}
          </div>

          <div className="bookings-page__content-wrapper">
            <div className="bookings-page__content-wrapper--left bookings-page__left-content">
              <div className="bookings-page__left-content-item">
                <Switch
                  label="include bookings with past check-in"
                  onChange={(e) => setShowPastCheckInBookings(e.target.checked)}
                  checked={showPastCheckInBookings}
                />
              </div>

              <div className=" bookings-page__left-content-item">
                <BookingSort
                  options={bookingStatuses}
                  selectedOption={selectedBookingStatus}
                  onSort={setSelectedBookingStatus}
                  placeholder="Sort by status.."
                />
              </div>

              <div className="bookings-page__left-content-item">
                <BookingSort
                  options={dateOptions}
                  selectedOption={selectedBookingPeriodDate}
                  onSort={setSelectedBookingPeriodDate}
                  placeholder="Sort by period.."
                />
              </div>
            </div>

            <div className="bookings-page__content-wrapper--right">
              <div className="white-block-container">
                <BookingsTopBar filteredBookingsData={additionalFilteredBookings} />

                <div className="records-list-container">
                  <BookingList bookings={paginatedBookings} bookingStatuses={bookingStatuses} />
                </div>

                {additionalFilteredBookings.length > BOOKINGS_PER_PAGE && (
                  <Pagination
                    activePage={activeNumberPage}
                    totalItemsCount={additionalFilteredBookings.length}
                    itemsCountPerPage={BOOKINGS_PER_PAGE}
                    pageRangeDisplayed={BOOKINGS_PAGES_SHOWN}
                    itemClass="pagination-item"
                    linkClass="pagination-link"
                    onChange={setActiveNumberPage}
                  />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ErrorBoundaryDecorator(true)(BookingsPage);
