import apiClient from '../../services/apiClient';

/**
 * Get "payments by selected property" request
 *
 * @param propertyId {number}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const getPaymentsBySelectedPropertyRequestAPI = (propertyId) =>
  apiClient
    .get(`payments/${propertyId}`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));

/**
 * Send "add new payment for selected property" request
 *
 * @param propertyId {number}
 * @param payload {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendAddPaymentRequestAPI = (propertyId, payload) =>
  apiClient
    .post(`payments/${propertyId}`, payload)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error.response));

/**
 * Send "delete payment for selected property" request
 *
 * @param propertyId {number}
 * @param payment {object}
 *
 * @return {Promise<AxiosResponse<any>>}
 */
export const sendDeletePaymentRequestAPI = (propertyId, payment) =>
  apiClient
    .delete(`payments/${propertyId}?payload=${JSON.stringify(payment)}`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error.response));
