import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';

import AuthContext from '../../../../context/AuthContext';
import Navigation from './Navigation';

/**
 * Private wrapper for secured routes
 */
const PrivateRoute = ({ hideNavigation, children }) => {
  const { authTokens } = useContext(AuthContext);

  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /login page
  if (!authTokens) {
    return <Navigate to="/login" />;
  }

  if (!hideNavigation) {
    return (
      <div className="navigation-wrapper">
        <Navigation />

        {children}
      </div>
    );
  }

  return children;
};

PrivateRoute.propTypes = {
  hideNavigation: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  hideNavigation: false,
};

export default PrivateRoute;
