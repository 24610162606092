import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import BookingsContext from '../../../context/BookingsContext';
import ErrorBoundaryDecorator from '../ErrorBoundaryDecorator';
import BookingSearch from '../../molecules/BookingSearch';
import RecordsTotals from '../../molecules/RecordsTotals';
import BookingSort from '../../molecules/BookingSort';

/**
 * Bookings top bar with search/totals/sort components
 */
const BookingsTopBar = ({ filteredBookingsData }) => {
  const {
    bookings,
    areBookingsPending,
    bookingSortByDateOption,
    onBookingSearchHandler,
    onBookingSortHandler,
  } = useContext(BookingsContext);

  return (
    <div className="white-block-container__top-bar">
      <BookingSearch onSearch={onBookingSearchHandler} />

      {!areBookingsPending && bookingSortByDateOption && (
        <RecordsTotals
          totalRecords={bookings.length}
          filteredRecords={filteredBookingsData.length}
        />
      )}

      <BookingSort onSort={onBookingSortHandler} selectedOption={bookingSortByDateOption} />
    </div>
  );
};

BookingsTopBar.propTypes = {
  filteredBookingsData: PropTypes.array.isRequired,
};

export default ErrorBoundaryDecorator()(BookingsTopBar);
