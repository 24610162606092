import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../atoms/Button';

/**
 * Render logout form
 */
const LogoutForm = ({ logOutHandler }) => {
  /**
   * Handles onSubmit event of form
   *
   * @param e {object}
   */
  const onSubmitHandler = (e) => {
    e.preventDefault();

    logOutHandler();
  };

  return (
    <form onSubmit={onSubmitHandler}>
      <Button type="submit" className="btn-logout">
        Log Out
      </Button>
    </form>
  );
};

LogoutForm.propTypes = {
  logOutHandler: PropTypes.func.isRequired,
};

export default LogoutForm;
